import React from 'react';
import styles from './ErrorMessage.module.css';

type ErrorMessageProps = {
    error?: Error,
    message?: string,
    children?: any,
}

const ErrorMessage = (props: ErrorMessageProps) => {
    const { error, message, children } = props;

    const title = message || error?.message || 'Error';

    return (
        <div className={styles.error}>
            {title && <h1>{title}</h1>}
            {children}
        </div>
    );
}

export default ErrorMessage;