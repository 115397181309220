import React from 'react';
import styles from './HeaderButtons.module.css';
import { Column, BaseRow } from '../modals/common';
import { CAN_PASTE, getFoilsFromClipboard, getFoilsFromText, useOnPaste } from '../util/clipboard';
import { exportRows } from '../util/export';

type HeaderButtonsProps<R extends BaseRow> = {
    columns: Column<R>[],
    rows: R[],
    exportFilename: string,
    onPasteFoils: (foils: string[]) => void,
    onReload: () => void,
}

function HeaderButtons<R extends BaseRow>(props: HeaderButtonsProps<R>) {
    const { columns, rows, exportFilename, onPasteFoils, onReload } = props;

    const pasteFromExcel = async () => {
        const foils = await getFoilsFromClipboard();
        onPasteFoils(foils);
    };

    useOnPaste((text) => {
        const foils = getFoilsFromText(text);
        onPasteFoils(foils);
    });

    const exportToCSV = () => {
        exportRows(exportFilename + '.csv', rows, columns);
    }

    return (
        <div className={styles.buttonsWrapper}>
            <div className={styles.buttons}>
                {CAN_PASTE &&
                    <button onClick={pasteFromExcel}>
                        <span role="img" aria-label='Paste icon'>📋</span>&nbsp;Paste column from Excel
                    </button>
                }
                <button onClick={exportToCSV}>⤓&nbsp;Export data</button>
                <button onClick={onReload}>↻&nbsp;Reload data</button>
            </div>
        </div>
    );
}

export default HeaderButtons;

