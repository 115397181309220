import { BatchWithFoils } from '../modals/common';
import { ListProductsProduct } from '../azumuta';
import { listProducts } from '../azumuta-api';

type ProductParameters = {
    foils?: string[],
    cs_foils?: string[],
    L_foils?: string[],
    R_foils?: string[],
}

/**
 * Fetch all batches with one of the specified prefixes
 * @param prefixes The prefixes to the match the batches to
 */
export const fetchBatches = async (prefixes: string[]): Promise<BatchWithFoils[][]> => {
    const responses = await Promise.all(prefixes.map((prefix) => listProducts({identifier: prefix, limit: 1000})));
    const batches = responses.map((response) => convertToBatches(response.data));

    return batches;
}

const convertToBatches = (data: ListProductsProduct[]): BatchWithFoils[] => data
    .map((product: ListProductsProduct) => {
        const batch: BatchWithFoils = {
            batchNumber: product.identifier,
            status: product.status,
        };

        if (!product.parameters) {
            return batch;
        }

        const {parameters}: {parameters: ProductParameters} = product;

        if (parameters.foils) {
            batch.foils = parameters.foils;
        }

        if (parameters.cs_foils) {
            batch.cs_foils = parameters.cs_foils;
        }

        // Concatenate `L_foils` and `R_foils` into `foils`

        if (parameters.L_foils) {
            batch.foils = batch.L_foils = parameters.L_foils;
        }

        if (parameters.R_foils) {
            if (batch.foils) {
                batch.foils = batch.foils.concat(parameters.R_foils);
            }
            else {
                batch.foils = parameters.R_foils;
            }

            batch.R_foils = parameters.R_foils;
        }

        return batch;
    })
    .filter((batch) => batch.foils || batch.cs_foils) as BatchWithFoils[];

export const excludeCurrentBatch = (batches: BatchWithFoils[], currentBatchNumber?: string): BatchWithFoils[] => {
    if (!currentBatchNumber) {
        return batches;
    }

    return batches.filter((batch) => batch.batchNumber !== currentBatchNumber);
}

