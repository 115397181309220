import {useMemo, useRef}                from 'react';
import debounce                         from 'lodash.debounce';


/**
 * `useDebouncedCallback` executes the passed function only if the returned function isn't called for a `wait` number of milliseconds
 * @param func 
 * @param wait 
 */
export const useDebouncedCallback = (func: (value: any) => void, wait: number) => {
    const funcRef = useRef<(value: any) => void>();
    funcRef.current = func;

    return useMemo(
        () => debounce(
            (value: any) => funcRef.current?.(value),
            wait
        ),
        [wait]
    );
};
