import React from 'react';
import styles from './LoadingOverlay.module.css';

type LoadingOverlayProps = {
    loading: boolean,
}

const LoadingOverlay = (props: LoadingOverlayProps) => {
    const { loading } = props;


    return (
        <div
            className={styles.loadingWrapper}
            style={{
                opacity: loading ? 1 : 0,
                pointerEvents: loading ? 'all' : 'none',
            }}
        >
            <div className={styles.ballScale}>
                <div />
            </div>
        </div>
    );
}

export default LoadingOverlay;