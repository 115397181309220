import { SerializableProduct } from "../azumuta";
import { M5Row } from "../configs/m5.config";
import { M5FooterRow } from "./M5Footer";

export type M5ProductParameters = {
    frames: string[],
    remarks: string[],
    L_foils: string[],
    L_front_lenses: string[],
    L_back_lenses: string[],
    R_foils: string[],
    R_front_lenses: string[],
    R_back_lenses: string[],
}

export interface M5Product extends SerializableProduct {
    parameters: M5ProductParameters
}

export const getParametersFromFooterRows = (footerRows: M5FooterRow[]): M5ProductParameters => {
    const resolvedParameters: M5ProductParameters = {
        L_foils: [], R_foils: [], frames: [], L_front_lenses: [], L_back_lenses: [], R_front_lenses: [], R_back_lenses: [], remarks: []
    }

    for (const row of footerRows) {
        resolvedParameters.frames.push(row.frameNumber);
        resolvedParameters.remarks.push(row.remarks);
        resolvedParameters.L_foils.push(row.leftFoilNumber);
        resolvedParameters.L_front_lenses.push(row.leftFoilFrontLens);
        resolvedParameters.L_back_lenses.push(row.leftFoilBackLens);
        resolvedParameters.R_foils.push(row.rightFoilNumber);
        resolvedParameters.R_front_lenses.push(row.rightFoilFrontLens);
        resolvedParameters.R_back_lenses.push(row.rightFoilBackLens);
    }

    return resolvedParameters;
}

export const getFooterRowsFromParameters = (parameters: M5ProductParameters | undefined, foilRows: M5Row[]): M5FooterRow[] => {
    if (!parameters) {
        return [];
    }

    const { L_foils = [], R_foils = [], frames, L_front_lenses, L_back_lenses, R_front_lenses, R_back_lenses, remarks } = parameters;

    return Array.from({ length: Math.max(L_foils.length, R_foils.length) }, (_, index) => {
        const leftFoilNumber = L_foils[index];
        const rightFoilNumber = R_foils[index];
        const leftFoilBatchNumber = leftFoilNumber
            ? foilRows.find((row) => row.foilNumber === leftFoilNumber)?.batchNumber ?? 'Not found'
            : '';
        const rightFoilBatchNumber = rightFoilNumber
            ? foilRows.find((row) => row.foilNumber === rightFoilNumber)?.batchNumber ?? 'Not found'
            : '';

        return {
            frameNumber: frames?.[index],
            remarks: remarks?.[index],
            leftFoilBatchNumber,
            leftFoilNumber,
            leftFoilFrontLens: L_front_lenses?.[index],
            leftFoilBackLens: L_back_lenses?.[index],
            rightFoilBatchNumber,
            rightFoilNumber,
            rightFoilFrontLens: R_front_lenses?.[index],
            rightFoilBackLens: R_back_lenses?.[index],
        }
    });
}

export const getDefaultFooterRow = () => {
    return {
        frameNumber: '',
        remarks: '',
        leftFoilBatchNumber: '',
        leftFoilNumber: '',
        leftFoilFrontLens: '',
        leftFoilBackLens: '',
        rightFoilBatchNumber: '',
        rightFoilNumber: '',
        rightFoilFrontLens: '',
        rightFoilBackLens: '',
    };
}