import { RetrieveProductResponse, ListProductsResponse, ListProductsQuery } from './azumuta';
import queryString from 'query-string';

let apiDomain = 'https://test.azumuta.com';
export function setApiDomain(domain: string) {
    apiDomain = domain;
}

export function getApiDomain(): string {
    return apiDomain;
}

let apiKey: string;
export function setApiKey(key: string) {
    apiKey = key;
}

const buildAPIPath = (path: string) =>
    `${apiDomain}/api/v1/${path}${path.indexOf('?') > -1 ? '&' : '?'}apiKey=${apiKey}`;

export async function listProducts(query: ListProductsQuery): Promise<ListProductsResponse> {
    const path = buildAPIPath(`products?${queryString.stringify(query)}`);
    const res = await fetch(path);

    if (!res.ok) {
        throw new Error(`Couldn't complete request for ${path}, got "${await res.text()}"`)
    }

    return res.json();
};

export async function retrieveProduct(identifier: any): Promise<RetrieveProductResponse> {
    const path = buildAPIPath(`products/${identifier}`);
    const res = await fetch(path);

    if (!res.ok) {
        throw new Error(`Couldn't complete request for ${path}, got "${await res.text()}"`)
    }
    
    return res.json();
}
