import React, { useState } from 'react';
import FoilTable from '../components/FoilTable';
import { M4Row, M4_COLUMNS, M4_FILTERS, M4_SEARCH, M4_VALIDATE, M4SearchContext, M4_PREPARE_SEARCH } from '../configs/m4.config';
import { resolveFoilAvailability, resolveRowDetails, addCSFoilNumbers, indexRows, filterSelectedRows } from '../util/rows';
import Filter from '../components/Filter';
import styles from './Modal.module.css';
import headerStyles from '../components/Header.module.css';
import { useProductModel } from '../util/useProductModel';
import { filterRows } from '../util/filter';
import Modal from './Modal';
import { fetchBatches, excludeCurrentBatch } from '../util/batch';
import Footer from '../components/Footer';
import HeaderButtons from '../components/HeaderButtons';
import HeaderField from '../components/HeaderField';
import { useReloadable } from '../util/useReloadable';
import ErrorMessage from '../components/ErrorMessage';
import { resolvePastedFoilNumbers } from '../util/clipboard';


const M4 = ({ createMode }: { createMode: boolean }) => {
    const [rows, setRows] = useState<M4Row[]>([]);
    const [indexedRows, setIndexedRows] = useState<{ [foilNumber: string]: M4Row }>();
    const [query, setQuery] = useState<string>('');
    const [activeFilters, setActiveFilters] = useState<number[]>([]);

    const [product, updateProduct, productRef, initialProductRef] = useProductModel();

    const { loading, reload, error } = useReloadable(async () => {
        const [inputBatches, existingOutputBatches] = await fetchBatches(['^L-', '^A-']);
        const outputBatches = excludeCurrentBatch(existingOutputBatches, productRef.current?.identifier);

        const rows: M4Row[] = resolveFoilAvailability<M4Row>(inputBatches, outputBatches, 'foils');
        const availableRows = rows.filter((row) => row.available);
        const detailedRows: M4Row[] = await resolveRowDetails(availableRows, M4_COLUMNS, inputBatches);
        const rowsWithCSFoils: M4Row[] = addCSFoilNumbers(detailedRows, inputBatches);

        setRows(rowsWithCSFoils);
        setIndexedRows(indexRows(rowsWithCSFoils));
    }, []);

    const batchNumber = product?.identifier || '';
    const visibleRows = filterRows<M4Row, M4SearchContext>(rows, M4_FILTERS, M4_SEARCH, activeFilters, M4_PREPARE_SEARCH(query));
    const selectedRowKeys = product?.parameters?.foils || [];
    const selectedRows = filterSelectedRows(selectedRowKeys, indexedRows);
    const hasSelectedRows = selectedRows.length > 0;

    const updateSelectedRowKeys = (selectedRowKeys: string[]) => {
        updateProduct({
            parameters: {
                foils: selectedRowKeys,
            }
        });
    }

    const updateBatchNumber = (newBatchNumber: string) => {
        updateProduct({
            identifier: newBatchNumber
        });
    }

    const handlePasteFoils = async (foilNumbers: string[]) => {
        const newSelectedRowKeys = await resolvePastedFoilNumbers(foilNumbers, selectedRowKeys, [], indexedRows);

        if (!newSelectedRowKeys) {
            return;
        }

        updateSelectedRowKeys(newSelectedRowKeys);
    }
    if (error) {
        return (
            <ErrorMessage error={error} />
        )
    }

    return (
        <Modal
            loading={loading}
            onBeforeSubmit={() => M4_VALIDATE(product, createMode, initialProductRef.current)}
        >
            <header className={headerStyles.topHeader}>
                <HeaderField
                    id={'batchNumber'}
                    label={'Batch number'}
                    value={batchNumber}
                    onChange={updateBatchNumber}
                />
            </header>
            <header className={headerStyles.bottomHeader}>
                <Filter
                    query={query}
                    setQuery={setQuery}
                    filters={M4_FILTERS}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                />
                <HeaderButtons<M4Row>
                    columns={M4_COLUMNS}
                    rows={rows}
                    exportFilename={'Azumuta_export_M4'}
                    onPasteFoils={handlePasteFoils}
                    onReload={reload}
                />
            </header>
            <section className={styles.inputTableWrapper}>
                <FoilTable<M4Row>
                    columns={M4_COLUMNS}
                    rows={visibleRows}
                    selectedRowKeys={selectedRowKeys}
                    sortable={true}
                    onRowToggle={updateSelectedRowKeys}
                />
            </section>
            {hasSelectedRows &&
                <section className={styles.outputTableWrapper}>
                    <FoilTable<M4Row>
                        columns={M4_COLUMNS}
                        rows={selectedRows}
                        selectedRowKeys={selectedRowKeys}
                        onRowToggle={updateSelectedRowKeys}
                    />
                </section>
            }
            {hasSelectedRows &&
                <Footer
                    selectedRowCount={selectedRowKeys.length}
                    onClear={() => updateSelectedRowKeys([])}
                />
            }
        </Modal>
    );
}

export default M4;

