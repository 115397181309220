import React from 'react';
import styles from './App.module.css';

type AppProps = {
  component?: React.ComponentType<{ createMode: boolean }>,
  endpoints: string[],
  createMode: boolean,
  apiKey: string | undefined
}

const App = (props: AppProps) => {
  const { component: Component, endpoints, createMode, apiKey } = props;

  if (!apiKey) {
    return (
      <div className={styles.warningLayout}>
        <h1>Invalid apiKey</h1>
      </div>
    )
  }

  if (!Component) {
    return (
      <div className={styles.warningLayout}>
        <h1>Invalid endpoint</h1>
        <p>Available endpoints: </p>
        <ul>
          {endpoints.map((path) =>
            <li key={path}>
              <a href={path}>{path}</a>
            </li>
          )}
        </ul>
      </div>
    )
  }

  return (
    <div className={styles.appLayout}>
      <Component
        createMode={createMode}
      />
    </div>
  );
}


export default App;
