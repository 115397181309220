import React from 'react';
import { SerializableProduct } from "../azumuta";
import { swalConfirm, swalError } from "../util/sweetalert";
import ChangedFoilsList from '../components/ChangedFoilsList';
import { arraysEqual } from '../util/diffArrays';


export const baseValidate = async (
    product: SerializableProduct | undefined,
    foilArrayProp: string,
    createMode: boolean,
    initialProduct?: SerializableProduct | null
): Promise<boolean> => {
    if (!product) {
        return false;
    }

    const foils = product.parameters?.[foilArrayProp] || [];

    if (createMode) {
        const isEmpty = !foils.length;
        const { dismiss } = await swalConfirm(isEmpty
            ? 'Do you want to create an EMPTY batch?'
            : 'Do you want to create a batch?'
        );

        if (dismiss) {
            return false;
        }
    }
    else if (initialProduct) {
        const initialFoils = initialProduct.parameters?.[foilArrayProp] || [];

        if (arraysEqual(foils, initialFoils)) {
            const { dismiss } = await swalConfirm('Selected foils haven\'t changed. Continue anyway?');
            if (dismiss) {
                return false;
            }
        }
        else {
            const { dismiss } = await swalConfirm('Do you want to update this batch?', (
                <ChangedFoilsList
                    initialFoils={initialFoils}
                    updatedFoils={foils}
                />
            ))

            if (dismiss) {
                return false;
            }
        }
    }
    else {
        await swalError('Something went wrong');

        return false;
    }

    return true;
}