import { useState, useEffect, useCallback } from 'react';

/**
 * `useReloadable` abstracts away the execution management of the `loadFn`.
 * The `loadFn` gets called a first time on mount.
 * It returns a boolean `loading`, that signifies whether loading is in progress, 
 * and a function `reload`, that will re-execute the `loadFn` when called
 */
export const useReloadable = (loadFn: () => Promise<any>, deps: any[]): { loading: boolean, reload: () => void, error?: Error } => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const reload = useCallback(async () => {
        setLoading(true);
        try {
            await loadFn();
        } catch (e) {
            console.error(e);
            setError(e);
        }
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    useEffect(() => {
        reload();
    }, [reload]);

    return { loading, reload, error };
}