import Swal from 'sweetalert2';
import withReactContent, { ReactElementOr } from 'sweetalert2-react-content'

const SwalWithReactContent = withReactContent(Swal);

export default SwalWithReactContent;

export const swalConfirm = (title: string, html?: ReactElementOr<'html'>) =>
    SwalWithReactContent.fire({
        title,
        html,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        reverseButtons: true,
        heightAuto: false,
    });

export const swalError = (title: string) =>
    SwalWithReactContent.fire({
        title,
        icon: 'error',
        confirmButtonText: 'Ok',
        heightAuto: false,
    });