import { Row, DetailedColumn, RowFilter } from "../modals/common";
import { isEmpty, makeFoilNumberSearchContext, matchesFoilNumberSearchContext, getSearchableProps, matchesAnyProperty } from "../util/filter";
import { SerializableProduct } from "../azumuta";
import { baseValidate } from './validate';

export const M2_TEMPLATE = 'm2-nanoimprint';

export interface M2Row extends Row {
    batchNumber: string,
    foilNumber: string,
    available: boolean,
    agResistance?: string,
    isolationOKNOK?: string,
    itoConductance?: string,
    remarks?: string,
    nextBatchNumber?: string,
}

export const M2_COLUMNS: DetailedColumn<M2Row>[] = [
    {
        title: 'Batch number',
        prop: 'batchNumber',
        searchable: true,
    },
    {
        title: 'Foil number',
        prop: 'foilNumber',
        searchable: true,
    },
    {
        title: 'Ag resistance (Ohm)',
        prop: 'agResistance',
        workinstructionId: '5RyZMPjzbkAKssCG7',
        instructionUuid: 'adcd3f75-2a8f-4838-bebd-bd49c333cd6a',
        foilColumn: 'Foil number',
        answerColumn: 'Ag resistance [Ohm]',
        propType: 'number',
        searchable: true,
    },
    {
        title: 'Isolation OK/NOK',
        prop: 'isolationOKNOK',
        workinstructionId: '5RyZMPjzbkAKssCG7',
        instructionUuid: 'adcd3f75-2a8f-4838-bebd-bd49c333cd6a',
        foilColumn: 'Foil number',
        answerColumn: 'Isolation OK/NOK',
        searchable: true,
    },
    {
        title: 'ITO conductance',
        prop: 'itoConductance',
        workinstructionId: '5RyZMPjzbkAKssCG7',
        instructionUuid: 'adcd3f75-2a8f-4838-bebd-bd49c333cd6a',
        foilColumn: 'Foil number',
        answerColumn: 'ITO conductance',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'remarks',
        workinstructionId: '5RyZMPjzbkAKssCG7',
        instructionUuid: 'adcd3f75-2a8f-4838-bebd-bd49c333cd6a',
        foilColumn: 'Foil number',
        answerColumn: 'Remarks',
        propType: 'string',
        searchable: true,
    },
    // {
    //     title: 'M2 batch number',
    //     prop: 'nextBatchNumber',
    // }
];

export type M2SearchContext = {
    query: string,
    foilQuery?: string,
    alternativeFoilQuery?: string,
}

export const M2_PREPARE_SEARCH = (query: string): M2SearchContext | undefined => {
    if (!query) {
        return undefined;
    }

    const context: M2SearchContext = {
        query: query.toLowerCase(),
    };

    Object.assign(context, makeFoilNumberSearchContext(context.query));

    return context;
}

const searchableProps = getSearchableProps(M2_COLUMNS);
export const M2_SEARCH = (row: M2Row, context: M2SearchContext): boolean => {
    const { query } = context;

    if (matchesFoilNumberSearchContext(context, row.foilNumber)) {
        return true;
    }

    if (matchesAnyProperty<M2Row>(context.query, row, searchableProps)) {
        return true;
    }

    return row.foilNumber.toLowerCase().startsWith(query);
}

export const M2_FILTERS: RowFilter<M2Row>[] = [
    {
        label: 'Hide all NOK',
        callback: (row: M2Row) => true, // todo
    },
    {
        label: 'Hide items with unfilled Quality Check',
        callback: (row: M2Row) => !isEmpty(row.isolationOKNOK),
    },
];

export const M2_VALIDATE = async (
    product: SerializableProduct | undefined,
    createMode: boolean,
    initialProduct?: SerializableProduct | null
): Promise<boolean> => {
    return baseValidate(product, 'foils', createMode, initialProduct);
};
