import React from 'react';
import styles from './TableInput.module.css';

type TableInputProps = {
    value: string,
    onChange: (newValue: string) => void,
}

const TableInput = (props: TableInputProps) => {
    const { value, onChange } = props;

    return (
        <div className={styles.tableInputWrapper}>
            <span className={styles.tableInputPencil}>✎</span>
            <input
                className={styles.tableInput}
                type={'text'}
                value={value || ''}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}

export default TableInput;