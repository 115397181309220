import React, { useState } from 'react';
import FoilTable from '../components/FoilTable';
import { M3LRow, M3L_FOILS_COLUMNS, M3L_FILTERS, M3L_CS_FOILS_COLUMNS, M3L_SEARCH, M3L_VALIDATE, M3LSearchContext, M3L_PREPARE_SEARCH } from '../configs/m3l.config';
import { resolveFoilAvailability, resolveRowDetails, indexRows, filterSelectedRows } from '../util/rows';
import Filter from '../components/Filter';
import styles from './Modal.module.css';
import headerStyles from '../components/Header.module.css';
import { useProductModel } from '../util/useProductModel';
import { filterRows } from '../util/filter';
import Modal from './Modal';
import { fetchBatches, excludeCurrentBatch } from '../util/batch';
import Footer from '../components/Footer';
import HeaderButtons from '../components/HeaderButtons';
import HeaderField from '../components/HeaderField';
import { useReloadable } from '../util/useReloadable';
import ErrorMessage from '../components/ErrorMessage';
import { resolvePastedFoilNumbers } from '../util/clipboard';


const M3L = ({ createMode }: { createMode: boolean }) => {
    const [foilRows, setFoilRows] = useState<M3LRow[]>([]);
    const [indexedFoilRows, setIndexedFoilRows] = useState<{ [foilNumber: string]: M3LRow }>();
    const [cSFoilRows, setCSFoilRows] = useState<M3LRow[]>([]);
    const [indexedCSFoilRows, setIndexedCSFoilRows] = useState<{ [foilNumber: string]: M3LRow }>();

    const [foilQuery, setFoilQuery] = useState<string>('');
    const [cSFoilQuery, setCSFoilQuery] = useState<string>('');
    const [activeFoilFilters, setActiveFoilFilters] = useState<number[]>([]);
    const [activeCSFoilFilters, setActiveCSFoilFilters] = useState<number[]>([]);

    const [product, updateProduct, productRef, initialProductRef] = useProductModel();

    const { loading, reload, error } = useReloadable(async () => {
        const [inputBatches, existingOutputBatches] = await fetchBatches(['^C-', '^L-']);
        const outputBatches = excludeCurrentBatch(existingOutputBatches, productRef.current?.identifier);

        const foilRows: M3LRow[] = resolveFoilAvailability<M3LRow>(inputBatches, outputBatches, 'foils');
        const availableFoilRows = foilRows.filter((row) => row.available);
        const detailedFoilRows: M3LRow[] = await resolveRowDetails(availableFoilRows, M3L_FOILS_COLUMNS, inputBatches);

        setFoilRows(detailedFoilRows);
        setIndexedFoilRows(indexRows(detailedFoilRows));

        const cSFoilRows: M3LRow[] = resolveFoilAvailability<M3LRow>(inputBatches, outputBatches, 'cs_foils');
        const availableCSFoilRows = cSFoilRows.filter((row) => row.available);
        const detailedCSFoilRows: M3LRow[] = await resolveRowDetails(availableCSFoilRows, M3L_CS_FOILS_COLUMNS, inputBatches);

        setCSFoilRows(detailedCSFoilRows);
        setIndexedCSFoilRows(indexRows(detailedCSFoilRows));
    }, []);

    const batchNumber = product?.identifier || '';

    const visibleFoilRows = filterRows<M3LRow, M3LSearchContext>(foilRows, M3L_FILTERS, M3L_SEARCH, activeFoilFilters, M3L_PREPARE_SEARCH(foilQuery));
    const selectedFoilRowKeys = product?.parameters?.foils || [];
    const selectedFoilRows = filterSelectedRows(selectedFoilRowKeys, indexedFoilRows);
    const hasSelectedFoilRows = selectedFoilRows.length > 0;

    const visibleCSFoilRows = filterRows<M3LRow, M3LSearchContext>(cSFoilRows, M3L_FILTERS, M3L_SEARCH, activeCSFoilFilters, M3L_PREPARE_SEARCH(cSFoilQuery));
    const selectedCSFoilRowKeys = product?.parameters?.cs_foils || [];
    const selectedCSFoilRows = filterSelectedRows(selectedCSFoilRowKeys, indexedCSFoilRows)
    const hasSelectedCSFoilRows = selectedCSFoilRows.length > 0;

    const updateSelectedFoilRowKeys = (selectedRowKeys: string[]) => {
        updateProduct({
            parameters: {
                ...product?.parameters,
                foils: selectedRowKeys,
            }
        });
    }

    const updateSelectedCSFoilRowKeys = (selectedRowKeys: string[]) => {
        updateProduct({
            parameters: {
                ...product?.parameters,
                cs_foils: selectedRowKeys,
            }
        });
    }

    const updateBatchNumber = (newBatchNumber: string) => {
        updateProduct({
            identifier: newBatchNumber
        });
    }

    const handlePasteFoils = async (foilNumbers: string[]) => {
        const unlock = await waitForMutex();
        const newSelectedFoilRowKeys = await resolvePastedFoilNumbers(foilNumbers, selectedFoilRowKeys, [], indexedFoilRows);

        if (!newSelectedFoilRowKeys) {
            unlock();
            return;
        }

        updateSelectedFoilRowKeys(newSelectedFoilRowKeys);
        unlock();
    }
    const handlePasteCSFoils = async (foilNumbers: string[]) => {
        const unlock = await waitForMutex();
        const newSelectedCSFoilRowKeys = await resolvePastedFoilNumbers(foilNumbers, selectedCSFoilRowKeys, [], indexedCSFoilRows);

        if (!newSelectedCSFoilRowKeys) {
            unlock();
            return;
        }

        updateSelectedCSFoilRowKeys(newSelectedCSFoilRowKeys);
        unlock();
    }

    if (error) {
        return (
            <ErrorMessage error={error} />
        )
    }

    return (
        <Modal
            loading={loading}
            onBeforeSubmit={() => M3L_VALIDATE(product, createMode, initialProductRef.current)}
        >
            <header className={headerStyles.topHeader}>
                <HeaderField
                    id={'batchNumber'}
                    label={'Batch number'}
                    value={batchNumber}
                    onChange={updateBatchNumber}
                />
            </header>
            <div className={styles.moduleColumns}>
                <aside className={styles.moduleColumn}>
                    <header className={headerStyles.bottomHeader}>
                        <Filter
                            query={foilQuery}
                            setQuery={setFoilQuery}
                            filters={M3L_FILTERS}
                            activeFilters={activeFoilFilters}
                            setActiveFilters={setActiveFoilFilters}
                        />
                        <HeaderButtons<M3LRow>
                            columns={M3L_FOILS_COLUMNS}
                            rows={foilRows}
                            exportFilename={'Azumuta_export_M3L_foils'}
                            onPasteFoils={handlePasteFoils}
                            onReload={reload}
                        />
                    </header>
                    <section className={styles.inputTableWrapper}>
                        <FoilTable<M3LRow>
                            columns={M3L_FOILS_COLUMNS}
                            rows={visibleFoilRows}
                            selectedRowKeys={selectedFoilRowKeys}
                            sortable={true}
                            onRowToggle={updateSelectedFoilRowKeys}
                        />
                    </section>
                    {hasSelectedFoilRows &&
                        <section className={styles.outputTableWrapper}>
                            <FoilTable<M3LRow>
                                columns={M3L_FOILS_COLUMNS}
                                rows={selectedFoilRows}
                                selectedRowKeys={selectedFoilRowKeys}
                                onRowToggle={updateSelectedFoilRowKeys}
                            />
                        </section>
                    }
                    {hasSelectedFoilRows &&
                        <Footer
                            selectedRowCount={selectedFoilRows.length}
                            onClear={() => updateSelectedFoilRowKeys([])}
                        />
                    }
                </aside>
                <aside className={styles.moduleColumn}>
                    <header className={headerStyles.bottomHeader}>
                        <Filter
                            query={cSFoilQuery}
                            setQuery={setCSFoilQuery}
                            filters={M3L_FILTERS}
                            activeFilters={activeCSFoilFilters}
                            setActiveFilters={setActiveCSFoilFilters}
                        />
                        <HeaderButtons<M3LRow>
                            columns={M3L_FOILS_COLUMNS}
                            rows={cSFoilRows}
                            exportFilename={'Azumuta_export_M3L_cs_foils'}
                            onPasteFoils={handlePasteCSFoils}
                            onReload={reload}
                        />
                    </header>
                    <section className={styles.inputTableWrapper}>
                        <FoilTable<M3LRow>
                            columns={M3L_CS_FOILS_COLUMNS}
                            rows={visibleCSFoilRows}
                            selectedRowKeys={selectedCSFoilRowKeys}
                            sortable={true}
                            onRowToggle={updateSelectedCSFoilRowKeys}
                        />
                    </section>
                    {hasSelectedCSFoilRows &&
                        <section className={styles.outputTableWrapper}>
                            <FoilTable<M3LRow>
                                columns={M3L_CS_FOILS_COLUMNS}
                                rows={selectedCSFoilRows}
                                selectedRowKeys={selectedCSFoilRowKeys}
                                onRowToggle={updateSelectedCSFoilRowKeys}
                            />
                        </section>
                    }
                    {hasSelectedCSFoilRows &&
                        <Footer
                            selectedRowCount={selectedCSFoilRowKeys.length}
                            onClear={() => updateSelectedCSFoilRowKeys([])}
                        />
                    }
                </aside>
            </div>
        </Modal>
    );
}

export default M3L;

// Don't let sweetalert chains mix
let pasteMutex: Promise<undefined> | undefined;
const waitForMutex = async () => {
    if (pasteMutex) {
        await pasteMutex;
    }
    let unlock = () => { };
    pasteMutex = new Promise((resolve) => unlock = resolve);

    return unlock;
}
