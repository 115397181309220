import React, { useEffect, useRef } from 'react';
import styles from './Modal.module.css';
import LoadingOverlay from '../components/LoadingOverlay';

type Validator = () => boolean | Promise<boolean>;

type ModalProps = {
    loading: boolean,
    onBeforeSubmit?: Validator,
    children: any,
}

type Azumuta = {
    onBeforeSubmit: (validator: () => Promise<Boolean>) => void,
}

const getAzumuta = (): Azumuta | undefined => {
    // @ts-ignore
    return window.Azumuta;
}

/**
 * `Modal` is the base component for every module.
 */
const Modal = (props: ModalProps) => {
    const { loading, onBeforeSubmit, children } = props;

    const hasOnBeforeSubmit = !!onBeforeSubmit;
    const onBeforeSubmitRef = useRef<Validator | undefined>();
    onBeforeSubmitRef.current = onBeforeSubmit;

    useEffect(() => {
        if (hasOnBeforeSubmit) {
            getAzumuta()?.onBeforeSubmit?.(async() => {
                const isValid = await onBeforeSubmitRef.current?.();

                return isValid || false;
            });
        }
    }, [hasOnBeforeSubmit]);

    return (
        <main className={styles.module}>
            {children}
            <LoadingOverlay loading={loading}/>
        </main>
    );
}

export default Modal;