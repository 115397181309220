import compare from 'diff-sequences';

export const arraysEqual = (input: string[], output: string[]): boolean => {
    if (input === output) {
        return true;
    }

    if (input.length !== output.length) {
        return false;
    }

    for (let index = 0; index < input.length; index++) {
        if (input[index] !== output[index]) {
            return false;
        }
    }

    return true;
}

type DiffArraysResult = ({ value: string, added?: boolean, removed?: boolean })[];

export const diffArrays = (input: string[], output: string[]): DiffArraysResult => {

    const compareLines = (i: number, j: number) =>
        input[i] === output[j];

    let x = 0; // current input line
    let y = 0; // current output line

    const diff = [];
    compare(input.length, output.length, compareLines, function (n, nx, ny) {
        while (x !== nx) {
            diff.push({ value: input[x++], removed: true });
        }
        while (y !== ny) {
            diff.push({ value: output[y++], added: true });
        }
        while (--n !== -1) {
            diff.push({ value: output[y++] });
            x++;
        }
    });

    while (x !== input.length) {
        diff.push({ value: input[x++], removed: true });
    }
    while (y !== output.length) {
        diff.push({ value: output[y++], added: true });
    }

    return diff;
}
