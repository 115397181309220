import { Row, DetailedColumn, RowFilter } from "../modals/common";
import { isEmpty, isEqualTo, makeFoilNumberSearchContext, matchesFoilNumberSearchContext, getSearchableProps, matchesAnyProperty } from "../util/filter";
import { SerializableProduct } from "../azumuta";
import { baseValidate } from "./validate";

export interface M4Row extends Row {
    batchNumber: string,
    foilNumber: string,
    csFoilNumber: string,
    available: boolean,
    dotTest1Results?: string,
    dotTest1Remarks?: string,
    nextBatchNumber?: string,
}

export const M4_COLUMNS: DetailedColumn<M4Row>[] = [
    {
        title: 'Batch number',
        prop: 'batchNumber',
        searchable: true,
    },
    {
        title: 'Foil number',
        prop: 'foilNumber',
        searchable: true,
    },
    {
        title: 'CS Foil number',
        prop: 'csFoilNumber',
        searchable: true,
    },
    {
        title: 'Dot Test 1 results',
        prop: 'dotTest1Results',
        workinstructionId: 'FhqPuscH5uBLbaXAp',
        instructionUuid: 'c23ea185-607b-414d-b450-198f11e0d954',
        foilColumn: 'Foil name',
        answerColumn: 'Best/ok/nok',
        searchable: true,
    },
    {
        title: 'Dot Test 1 remarks',
        prop: 'dotTest1Remarks',
        workinstructionId: 'FhqPuscH5uBLbaXAp',
        instructionUuid: 'c23ea185-607b-414d-b450-198f11e0d954',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    // {
    //     title: 'M4 batch number',
    //     prop: 'nextBatchNumber',
    // }
];

export type M4SearchContext = {
    query: string,
    foilQuery?: string,
    alternativeFoilQuery?: string,
}

export const M4_PREPARE_SEARCH = (query: string): M4SearchContext | undefined => {
    if (!query) {
        return undefined;
    }

    const context: M4SearchContext = {
        query: query.toLowerCase(),
    };

    Object.assign(context, makeFoilNumberSearchContext(context.query));

    return context;
}

const searchableProps = getSearchableProps(M4_COLUMNS);
export const M4_SEARCH = (row: M4Row, context: M4SearchContext): boolean => {
    const { query } = context;

    if (matchesFoilNumberSearchContext(context, row.foilNumber)) {
        return true;
    }

    if (matchesFoilNumberSearchContext(context, row.csFoilNumber)) {
        return true;
    }

    if (matchesAnyProperty<M4Row>(context.query, row, searchableProps)) {
        return true;
    }

    return row.foilNumber.toLowerCase().startsWith(query);
}

export const M4_FILTERS: RowFilter<M4Row>[] = [
    {
        label: 'Hide all NOK',
        callback: (row: M4Row) => !isEqualTo(row.dotTest1Results, 'NOK'),
    },
    {
        label: 'Hide items with unfilled dot test',
        callback: (row: M4Row) => !isEmpty(row.dotTest1Results),
    },
];

export const M4_VALIDATE = async (
    product: SerializableProduct | undefined,
    createMode: boolean,
    initialProduct?: SerializableProduct | null
): Promise<boolean> => {
    return baseValidate(product, 'foils', createMode, initialProduct);
};
