import { Row, DetailedColumn, RowFilter } from "../modals/common";
import { isEmpty, isEqualTo, makeFoilNumberSearchContext, matchesFoilNumberSearchContext, getSearchableProps, matchesAnyProperty } from "../util/filter";
import { SerializableProduct } from "../azumuta";
import { baseValidate } from "./validate";

export interface M3NRow extends Row {
    batchNumber: string,
    foilNumber: string,
    available: boolean,
    blazeDeformation?: string,
    airBubblesInSpacers?: string,
    airBubbles?: string,
    otherDefects?: string,
    oKNOK?: string,
    nextBatchNumber?: string,
}

export const M3N_COLUMNS: DetailedColumn<M3NRow>[] = [
    {
        title: 'Batch number',
        prop: 'batchNumber',
        searchable: true,
    },
    {
        title: 'Foil number',
        prop: 'foilNumber',
        searchable: true,
    },
    {
        title: 'Blaze deformation',
        prop: 'blazeDeformation',
        workinstructionId: 'BkaMDjeNY52zujuim',
        instructionUuid: 'be8b6ff4-a50a-4dfb-90c4-78e09c642523',
        foilColumn: 'Foil name',
        answerColumn: 'Blaze deformation',
        searchable: true,
    },
    {
        title: 'Air bubbles in spacers',
        prop: 'airBubblesInSpacers',
        workinstructionId: 'BkaMDjeNY52zujuim',
        instructionUuid: 'be8b6ff4-a50a-4dfb-90c4-78e09c642523',
        foilColumn: 'Foil name',
        answerColumn: 'Air bubbles in spacers',
        searchable: true,
    },
    {
        title: 'Air bubbles',
        prop: 'airBubbles',
        workinstructionId: 'BkaMDjeNY52zujuim',
        instructionUuid: 'be8b6ff4-a50a-4dfb-90c4-78e09c642523',
        foilColumn: 'Foil name',
        answerColumn: 'Air bubbles',
        searchable: true,
    },
    {
        title: 'Other defects',
        prop: 'otherDefects',
        workinstructionId: 'BkaMDjeNY52zujuim',
        instructionUuid: 'be8b6ff4-a50a-4dfb-90c4-78e09c642523',
        foilColumn: 'Foil name',
        answerColumn: 'Other defects',
        searchable: true,
    },
    {
        title: 'OK/NOK',
        prop: 'oKNOK',
        workinstructionId: 'BkaMDjeNY52zujuim',
        instructionUuid: 'be8b6ff4-a50a-4dfb-90c4-78e09c642523',
        foilColumn: 'Foil name',
        answerColumn: 'OK/NOK',
        searchable: true,
    },
    // {
    //     title: 'M3 batch number',
    //     prop: 'nextBatchNumber',
    // },
];

export type M3NSearchContext = {
    query: string,
    foilQuery?: string,
    alternativeFoilQuery?: string,
}

export const M3N_PREPARE_SEARCH = (query: string): M3NSearchContext | undefined => {
    if (!query) {
        return undefined;
    }

    const context: M3NSearchContext = {
        query: query.toLowerCase(),
    };

    Object.assign(context, makeFoilNumberSearchContext(context.query));

    return context;
}

const searchableProps = getSearchableProps(M3N_COLUMNS);
export const M3N_SEARCH = (row: M3NRow, context: M3NSearchContext): boolean => {
    const { query } = context;

    if (matchesFoilNumberSearchContext(context, row.foilNumber)) {
        return true;
    }

    if (matchesAnyProperty<M3NRow>(context.query, row, searchableProps)) {
        return true;
    }

    return row.foilNumber.toLowerCase().startsWith(query);
}

export const M3N_FILTERS: RowFilter<M3NRow>[] = [
    {
        label: 'Hide all NOK',
        callback: (row: M3NRow) => !isEqualTo(row.oKNOK, 'NOK'),
    },
    {
        label: 'Hide items with unfilled Quality Check',
        callback: (row: M3NRow) => !isEmpty(row.oKNOK), // todo
    },
];

export const M3N_VALIDATE = async (
    product: SerializableProduct | undefined,
    createMode: boolean,
    initialProduct?: SerializableProduct | null
): Promise<boolean> => {
    return baseValidate(product, 'foils', createMode, initialProduct);
};