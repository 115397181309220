import React from 'react';
import styles from './PastedFoilsList.module.css';

type PastedFoilsListProps = {
    pastedFoils: string[],
    selectedFoils: string[],
    indexedFoils: { [foilNumber: string]: any },
}

const PastedFoilsList = (props: PastedFoilsListProps) => {
    const { pastedFoils, selectedFoils, indexedFoils } = props;

    return (
        <ul className={styles.pastedFoilsList}>
            {pastedFoils.map((key, keyIndex) => {
                const isSelected = selectedFoils.includes(key);
                const isAvailable = !!indexedFoils[key];

                return (
                    <li
                        key={keyIndex}
                        className={styles.pastedFoilsItem}
                        style={!isAvailable ? {
                            textDecoration: 'line-through'
                        } : undefined}
                    >
                        {key}
                        {isSelected &&
                        <span className={styles.pastedFoilCheck}>✔</span>
                        }
                    </li>
                );
            })}
        </ul>
    )
}

export default PastedFoilsList;