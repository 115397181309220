import React from 'react';
import styles from './Footer.module.css';

type FooterProps = {
    selectedRowCount: number,
    onClear: () => void,
}

const Footer = (props: FooterProps) => {
    const { selectedRowCount, onClear } = props;

    return (
        <footer className={styles.footer}>
            <span className={styles.footerArrow}>⤷</span>
            <span className={styles.footerCount}>{selectedRowCount} selected foils</span>
            <button
                className={styles.footerClearButton}
                onClick={onClear}
            >
                Clear selected
            </button>
        </footer>
    )
}

export default Footer;