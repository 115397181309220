import { Row, DetailedColumn, RowFilter } from "../modals/common";
import { isEmpty, makeFoilNumberSearchContext, matchesFoilNumberSearchContext, getSearchableProps, matchesAnyProperty } from "../util/filter";
import { SerializableProduct } from "../azumuta";
import { baseValidate } from "./validate";

export interface M3CSRow extends Row {
    batchNumber: string,
    foilNumber: string,
    available: boolean,
    agResistance?: string,
    isolationOKNOK?: string,
    itoConductance?: string,
    remarks?: string,
    nextBatchNumber?: string,
}

export const M3CS_COLUMNS: DetailedColumn<M3CSRow>[] = [
    {
        title: 'Batch number',
        prop: 'batchNumber',
        searchable: true,
    },
    {
        title: 'Foil number',
        prop: 'foilNumber',
        searchable: true,
    },
    {
        title: 'Ag resistance (Ohm)',
        prop: 'agResistance',
        workinstructionId: 'hGZdm7nFeYidGuZsR',
        instructionUuid: '5b3e79a2-0935-45b6-af32-c0aacfbb0dd2',
        foilColumn: 'Foil substrate  number',
        answerColumn: 'Ag resistance [Ohm]',
        propType: 'number',
        searchable: true,
    },
    {
        title: 'Isolation OK/NOK',
        prop: 'isolationOKNOK',
        workinstructionId: 'hGZdm7nFeYidGuZsR',
        instructionUuid: '5b3e79a2-0935-45b6-af32-c0aacfbb0dd2',
        foilColumn: 'Foil substrate  number',
        answerColumn: 'Isolation OK/NOK',
        searchable: true,
    },
    {
        title: 'ITO conductance',
        prop: 'itoConductance',
        workinstructionId: 'hGZdm7nFeYidGuZsR',
        instructionUuid: '5b3e79a2-0935-45b6-af32-c0aacfbb0dd2',
        foilColumn: 'Foil substrate  number',
        answerColumn: 'ITO conductance',
        propType: 'number',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'remarks',
        workinstructionId: 'hGZdm7nFeYidGuZsR',
        instructionUuid: '5b3e79a2-0935-45b6-af32-c0aacfbb0dd2',
        foilColumn: 'Foil substrate  number',
        answerColumn: 'Remarks',
        searchable: true,
    },
    // {
    //     title: 'M3CS batch number',
    //     prop: 'nextBatchNumber',
    // }
];

export type M3CSSearchContext = {
    query: string,
    foilQuery?: string,
    alternativeFoilQuery?: string,
}

export const M3CS_PREPARE_SEARCH = (query: string): M3CSSearchContext | undefined => {
    if (!query) {
        return undefined;
    }

    const context: M3CSSearchContext = {
        query: query.toLowerCase(),
    };

    Object.assign(context, makeFoilNumberSearchContext(context.query));

    return context;
}

const searchableProps = getSearchableProps(M3CS_COLUMNS);
export const M3CS_SEARCH = (row: M3CSRow, context: M3CSSearchContext): boolean => {
    const { query } = context;

    if (matchesFoilNumberSearchContext(context, row.foilNumber)) {
        return true;
    }

    if (matchesAnyProperty<M3CSRow>(context.query, row, searchableProps)) {
        return true;
    }

    return row.foilNumber.toLowerCase().startsWith(query);
}

export const M3CS_FILTERS: RowFilter<M3CSRow>[] = [
    {
        label: 'Hide all NOK',
        callback: (row: M3CSRow) => true, // todo
    },
    {
        label: 'Hide items with unfilled Quality Check',
        callback: (row: M3CSRow) => !isEmpty(row.isolationOKNOK),
    },
];

export const M3CS_VALIDATE = async (
    product: SerializableProduct | undefined,
    createMode: boolean,
    initialProduct?: SerializableProduct | null
): Promise<boolean> => {
    return baseValidate(product, 'cs_foils', createMode, initialProduct);
};