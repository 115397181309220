import React from 'react';
import styles from './SortButton.module.css';

type SortButtonProps = {
    mode: 'asc' | 'desc' | 'none',
    onClick: () => void,
}

// ▲ ▼ △ ▽
const SortButton = (props: SortButtonProps) => {
    const { mode, onClick } = props;

    return (
        <button
            className={styles.sortButton}
            onClick={onClick}
        >
            <span className={styles.sortButtonArrow}>{mode === 'desc' ? '▲' : '△'}</span>
            <span className={styles.sortButtonArrow}>{mode === 'asc' ? '▼' : '▽'}</span>
        </button>
    )
}

export default SortButton;