import React from 'react';
import { BaseRow } from "../modals/common";
import { useRef, useLayoutEffect } from "react";
import PastedFoilsList from '../components/PastedFoilsList';
import Swal from '../util/sweetalert';

export const CAN_PASTE = 'clipboard' in navigator && typeof navigator.clipboard.readText === 'function';

export const getFoilsFromText = (text: string, prefix?: string): string[] => {
    if (!text) {
        return [];
    }

    const rows = text.split('\n')
        .map((row) => row.trim())
        .filter(Boolean);

    if (prefix) {
        return rows.filter((row) => row.startsWith(prefix));
    }

    return rows;
}

export const getFoilsFromClipboard = async (prefix?: string): Promise<string[]> => {
    const text = await navigator.clipboard.readText();

    return getFoilsFromText(text, prefix);
}

export const useOnPaste = (callback: (text: string) => void) => {
    const callbackRef = useRef<(text: string) => void>();
    callbackRef.current = callback;

    useLayoutEffect(() => {
        const handler = (e: ClipboardEvent) => {
            const text = e.clipboardData?.getData('text');

            if (text && callbackRef.current) {
                callbackRef.current(text);
            }
        }

        document.body.addEventListener('paste', handler);

        return () => document.body.removeEventListener('paste', handler);
    }, []);
};

export const resolvePastedFoilNumbers = async (pastedRowKeys: string[], selectedRowKeys: string[], unavailableRowKeys: string[], indexedFoils?: { [foilNumber: string]: BaseRow }): Promise<string[] | undefined> => {
    if (!pastedRowKeys.length || !indexedFoils) {
        return;
    }

    const unavailableOrSelectedRowKeys = selectedRowKeys.concat(unavailableRowKeys);

    const availableRowKeys = pastedRowKeys.filter((key) => !!indexedFoils[key] && !unavailableRowKeys.includes(key));
    if (!availableRowKeys.length) {
        await Swal.fire({
            title: 'Can\'t match any pasted foil with an existing and available one',
            html: (
                <>
                    <p>You pasted:</p>
                    <PastedFoilsList
                        pastedFoils={pastedRowKeys}
                        selectedFoils={unavailableOrSelectedRowKeys}
                        indexedFoils={indexedFoils}
                    />
                </>
            ),
            icon: 'info',
            showCloseButton: true,
            showCancelButton: false,
            heightAuto: false,
        });

        return;

    }

    const newAvailableRowKeys = availableRowKeys.filter((key) => !selectedRowKeys.includes(key));
    if (!newAvailableRowKeys.length) {
        await Swal.fire({
            title: 'All pasted foils are already selected',
            html: (
                <>
                    <p>You pasted:</p>
                    <PastedFoilsList
                        pastedFoils={pastedRowKeys}
                        selectedFoils={unavailableOrSelectedRowKeys}
                        indexedFoils={indexedFoils}
                    />
                </>
            ),
            icon: 'info',
            showCloseButton: true,
            showCancelButton: false,
            heightAuto: false,
        });

        return;
    }

    if (!selectedRowKeys.length) {
        return newAvailableRowKeys;
    }

    const result = await Swal.fire({
        title: 'Do you want to overwrite or add?',
        html: (
            <>
                <p>You pasted {availableRowKeys.length} available foil number(s):</p>
                <PastedFoilsList
                    pastedFoils={pastedRowKeys}
                    selectedFoils={unavailableOrSelectedRowKeys}
                    indexedFoils={indexedFoils}
                />
                <p>Do you want to overwrite them, or add {newAvailableRowKeys.length} foil number(s) to the already selected foil numbers?</p>
            </>
        ),
        icon: 'question',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Overwrite',
        confirmButtonText: 'Add',
        heightAuto: false,
    });

    // Overwrite
    if (result.dismiss === Swal.DismissReason.cancel) {
        return availableRowKeys;
    }
    // Add
    else if (!result.dismiss) {
        return [...selectedRowKeys, ...newAvailableRowKeys];
    }

    return;
}