
export const BATCH_PREFIX_ORDER = ['S-', 'N-', 'C-', 'L-', 'A-', 'P-'];

export const FOIL_NUMBER_COLUMNS = [
    'Foil number',
    'foil number',
    'Foil name',
    'CS foil number',
    'CS-foil number',
    'Nano-imprinted foil name',
    'Nano-imprinted foil number',
    'Lens cell number',
    'Foil substrate number',
    'Foil substrate  number',
    'Counter-substrate foil number'
];
