import React, { useState } from 'react';
import HeaderField from '../components/HeaderField';
import headerStyles from '../components/Header.module.css';
import { listProducts, retrieveProduct } from '../azumuta-api';
import { RetrieveProductResponse } from '../azumuta';
import BatchOverview from './BatchOverview';
import styles from './SampleHistory.module.css';
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorMessage from '../components/ErrorMessage';
import { BATCH_PREFIX_ORDER } from '../configs/sample-history.config';
import { swalError } from '../util/sweetalert';
import orderBy from 'lodash.orderby';


const SampleHistory = () => {
    const [foilNumber, setFoilNumber] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [batches, setBatches] = useState<RetrieveProductResponse[]>([]);

    const fetchHistory = async () => {
        const trimmedFoilNumber = foilNumber.trim();

        if (!trimmedFoilNumber) {
            return;
        }

        setFoilNumber(trimmedFoilNumber);
        setLoading(true);
        try {
            const batchNumbers = await getInterestingBatchNumbers(trimmedFoilNumber);

            if (!batchNumbers.length) {
                await swalError('Foil number not found');
                setLoading(false);

                return;
            }

            const batches = await getInterestedBatches(batchNumbers);
            const sortedBatches = sortBatches(batches);

            setBatches(sortedBatches);
        }
        catch (e) {
            console.error(e);
            setError(e);
        }
        setLoading(false);
    };

    if (error) {
        return (
            <ErrorMessage error={error} />
        )
    }

    return (
        <div className={styles.wrapper}>
            <header className={headerStyles.topHeader}>
                <form
                    className={styles.headerForm}
                    onSubmit={(e) => {
                        e.preventDefault();
                        fetchHistory()
                    }}
                >
                    <HeaderField
                        id={'foilNumber'}
                        label={'Foil number'}
                        value={foilNumber}
                        onChange={setFoilNumber}
                    />
                    <button
                        type={'submit'}
                        className={styles.fetchButton}
                    >
                        Fetch
                    </button>
                </form>
            </header>
            <main className={styles.batches}>
                {batches.map((batch) => {
                    return (
                        <BatchOverview
                            key={batch.identifier}
                            batch={batch}
                            currentFoilNumber={foilNumber}
                        />
                    )
                })}
            </main>
            <LoadingOverlay loading={loading} />
        </div>
    );
}

export default SampleHistory;

const getInterestingBatchNumbers = async (foilNumber: string): Promise<string[]> => {
    const batchLists = await Promise.all([
        listProducts({ parameters: 'foils:' + foilNumber, archived: true }),
        listProducts({ parameters: 'cs_foils:' + foilNumber, archived: true }),
        listProducts({ parameters: 'L_foils:' + foilNumber, archived: true }),
        listProducts({ parameters: 'R_foils:' + foilNumber, archived: true }),
    ]);

    const batchNumbers = new Set<string>();
    for (const list of batchLists) {
        if (list.data) {
            for (const product of list.data) {
                batchNumbers.add(product.identifier);
            }
        }
    }

    return Array.from(batchNumbers);
}

const getInterestedBatches = async (batchNumbers: string[]): Promise<RetrieveProductResponse[]> => {
    return Promise.all(batchNumbers.map((batchNumber: string) => {
        return retrieveProduct(batchNumber);
    }))
}

const sortBatches = (batches: RetrieveProductResponse[]): RetrieveProductResponse[] => {
    return orderBy(batches, [(batch) => {
        const order = BATCH_PREFIX_ORDER.findIndex((prefix) => batch.identifier.startsWith(prefix));

        return order > -1 ? order : 1000;
    }]);
}
