import React from 'react';
import { Row, DetailedColumn, RowFilter } from "../modals/common";
import { makeFoilNumberSearchContext, matchesFoilNumberSearchContext, getSearchableProps, matchesAnyProperty } from "../util/filter";
import { swalConfirm, swalError } from "../util/sweetalert";
import { M5Product } from "../modals/m5Util";
import ChangedFoilsList from "../components/ChangedFoilsList";


export interface M5Row extends Row {
    batchNumber: string,
    foilNumber: string,
    csFoilNumber: string,
    available: boolean,
    pd?: string,
    frontLensBlank?: string,
    backLensBlank?: string,
    add?: string,
    power?: string,
    stackingRemarks?: string,
    c?: string,
    cRemarks?: string,
    dotTest2Result?: string,
    dotTest2Remarks?: string,
    m3BatchNumber?: string,
    frameNumber?: string,
    frameAssemblyRemarks?: string,
    nextBatchNumber?: string,
}

export const M5_COLUMNS: DetailedColumn<M5Row>[] = [
    {
        title: 'Batch number',
        prop: 'batchNumber',
        searchable: true,
    },
    {
        title: 'Foil number',
        prop: 'foilNumber',
        searchable: true,
    },
    {
        title: 'CS Foil number',
        prop: 'csFoilNumber',
        searchable: true,
    },
    {
        title: 'PD',
        prop: 'pd',
        workinstructionId: 'hgRzu6rrLWuZXzDmH',
        instructionUuid: 'c3bffde9-ebc5-4338-89c3-bd133a6e3c78',
        foilColumn: 'Foil name',
        answerColumn: 'PD',
        searchable: true,
    },
    {
        title: 'Front Lens Blank',
        prop: 'frontLensBlank',
        workinstructionId: 'tKzwYpRvDvoP3DhxJ',
        instructionUuid: '0077d1b1-2a1e-43b5-8b4c-daf74df4f059',
        foilColumn: 'Foil number',
        answerColumn: 'Front lens blank',
        searchable: true,
    },
    {
        title: 'Back Lens Blank',
        prop: 'backLensBlank',
        workinstructionId: 'tKzwYpRvDvoP3DhxJ',
        instructionUuid: '0077d1b1-2a1e-43b5-8b4c-daf74df4f059',
        foilColumn: 'Foil number',
        answerColumn: 'Back lens blank',
        searchable: true,
    },
    {
        title: 'Add [D]',
        prop: 'add',
        workinstructionId: 'tKzwYpRvDvoP3DhxJ',
        instructionUuid: '0077d1b1-2a1e-43b5-8b4c-daf74df4f059',
        foilColumn: 'Foil number',
        answerColumn: 'Add [D]',
        propType: 'number',
        searchable: true,
    },
    {
        title: 'Power [D]',
        prop: 'power',
        workinstructionId: 'tKzwYpRvDvoP3DhxJ',
        instructionUuid: '0077d1b1-2a1e-43b5-8b4c-daf74df4f059',
        foilColumn: 'Foil number',
        answerColumn: 'Power [D]',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'stackingRemarks',
        workinstructionId: 'tKzwYpRvDvoP3DhxJ',
        instructionUuid: '0077d1b1-2a1e-43b5-8b4c-daf74df4f059',
        foilColumn: 'Foil number',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'C [nF]',
        prop: 'c',
        workinstructionId: 'be9NNKEir8ueEeiak',
        instructionUuid: 'eaa51764-d800-42fb-b847-fa5cedb5b34d',
        foilColumn: 'Foil number',
        answerColumn: 'C [nF]',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'cRemarks',
        workinstructionId: 'FhqPuscH5uBLbaXAp',
        instructionUuid: 'c23ea185-607b-414d-b450-198f11e0d954',
        foilColumn: 'Foil number',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'Dot Test 2 result',
        prop: 'dotTest2Result',
        workinstructionId: 'NenLFvvz6zyYgBXec',
        instructionUuid: '<todo>',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'dotTest2Remarks',
        workinstructionId: 'NenLFvvz6zyYgBXec',
        instructionUuid: '<todo>',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'M3 Batch number',
        prop: 'm3BatchNumber',
        workinstructionId: 'FhqPuscH5uBLbaXAp',
        instructionUuid: 'c23ea185-607b-414d-b450-198f11e0d954',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'Frame number',
        prop: 'frameNumber',
        workinstructionId: 'FhqPuscH5uBLbaXAp',
        instructionUuid: 'c23ea185-607b-414d-b450-198f11e0d954',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'Frame assembly remarks',
        prop: 'frameAssemblyRemarks',
        workinstructionId: 'FhqPuscH5uBLbaXAp',
        instructionUuid: 'c23ea185-607b-414d-b450-198f11e0d954',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    // {
    //     title: 'M5 batch number',
    //     prop: 'nextBatchNumber',
    // }
];

export type M5SearchContext = {
    query: string,
    foilQuery?: string,
    alternativeFoilQuery?: string,
}

export const M5_PREPARE_SEARCH = (query: string): M5SearchContext | undefined => {
    if (!query) {
        return undefined;
    }

    const context: M5SearchContext = {
        query: query.toLowerCase(),
    };

    Object.assign(context, makeFoilNumberSearchContext(context.query));

    return context;
}

const searchableProps = getSearchableProps(M5_COLUMNS);
export const M5_SEARCH = (row: M5Row, context: M5SearchContext): boolean => {
    const { query } = context;

    if (matchesFoilNumberSearchContext(context, row.foilNumber)) {
        return true;
    }

    if (matchesFoilNumberSearchContext(context, row.csFoilNumber)) {
        return true;
    }

    if (matchesAnyProperty<M5Row>(context.query, row, searchableProps)) {
        return true;
    }

    return row.foilNumber.toLowerCase().startsWith(query);
}

export const M5_FILTERS: RowFilter<M5Row>[] = [
    {
        label: 'Hide all NOK',
        callback: (row: M5Row) => true, // todo
    },
    {
        label: 'Hide items with unfilled dot test',
        callback: (row: M5Row) => true // todo,
    },
];


export const M5_VALIDATE = async (product: M5Product | undefined, createMode: boolean, initialProduct?: M5Product | null): Promise<boolean> => {
    if (!product?.parameters) {
        return false;
    }

    const frames = product.parameters.frames;
    const LFoils = product.parameters.L_foils;
    const RFoils = product.parameters.R_foils;

    if (!LFoils || !RFoils || !frames || LFoils.length !== RFoils.length || LFoils.length !== frames.length) {
        await swalError('Something went wrong');

        return false;
    }

    const isEmpty = !LFoils.length;
    if (!isEmpty) {

        if (LFoils.some((foilNumber: string) => !foilNumber) || RFoils.some((foilNumber: string) => !foilNumber)) {
            await swalError('Some foils are not filled in');

            return false;
        }

        if (frames.some((frameNumber: string) => !frameNumber)) {
            await swalError('Some frame numbers are not filled in');

            return false;
        }
    }

    if (createMode) {
        const { dismiss } = await swalConfirm(isEmpty
            ? 'Do you want to create an EMPTY batch?'
            : 'Do you want to create a batch?'
        );

        if (dismiss) {
            return false;
        }
    }
    else if (initialProduct?.parameters) {
        const initialLFoils = initialProduct.parameters.L_foils || [];
        const initialRFoils = initialProduct.parameters.R_foils || [];

        const { dismiss } = await swalConfirm('Do you want to update this batch?', (
            <>
                <p style={{ textAlign: 'left' }}>Left foils:</p>
                <ChangedFoilsList
                    initialFoils={initialLFoils}
                    updatedFoils={LFoils}
                />
                <p style={{ textAlign: 'left' }}>Right foils:</p>
                <ChangedFoilsList
                    initialFoils={initialRFoils}
                    updatedFoils={RFoils}
                />
            </>
        ))

        if (dismiss) {
            return false;
        }
    }
    else {
        await swalError('Something went wrong');

        return false;
    }

    return true;
};
