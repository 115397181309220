import { BaseRow, Column } from "../modals/common";

export const exportRows = <R extends BaseRow>(filename: string, rows: R[], columns: Column<R>[]) => {
    const headerRow = columns
        .map((column) => createSafeValue(column.title))
        .join(',');

    const data: string[] = rows.map((row) =>
        columns.map((column) => createSafeValue(row[column.prop] || '')).join(',')
    )

    const file = [headerRow, ...data].join('\n');
    const blob = new Blob([file], { type: 'text/csv;charset=utf-8;' })

    downloadBlob(filename, blob);
}

const createSafeValue = (value: any): string => {
    if (typeof value === 'number') {
        return `"${value}"`;
    }

    if (typeof value === 'string') {
        if (value.search(/("|,|\n)/g) >= 0) {
            return `"${value}"`;
        }

        return value;
    }

    return '';
}

const downloadBlob = (filename: string, blob: Blob) => {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}