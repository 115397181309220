import React, { useState } from 'react';
import FoilTable from '../components/FoilTable';
import { getParametersFromFooterRows, getDefaultFooterRow, M5Product, getFooterRowsFromParameters } from './m5Util';
import { M5Row, M5_COLUMNS, M5_FILTERS, M5_SEARCH, M5_VALIDATE, M5SearchContext, M5_PREPARE_SEARCH } from '../configs/m5.config';
import { resolveFoilAvailability, resolveRowDetails, addCSFoilNumbers, indexRows, filterSelectedRows } from '../util/rows';
import Filter from '../components/Filter';
import styles from './Modal.module.css';
import headerStyles from '../components/Header.module.css';
import { useProductModel } from '../util/useProductModel';
import { filterRows } from '../util/filter';
import Modal from './Modal';
import { fetchBatches, excludeCurrentBatch } from '../util/batch';
import HeaderButtons from '../components/HeaderButtons';
import M5Footer, { M5FooterRow } from './M5Footer';
import { useReloadable } from '../util/useReloadable';
import ErrorMessage from '../components/ErrorMessage';
import HeaderField from '../components/HeaderField';
import { resolvePastedFoilNumbers } from '../util/clipboard';
import Swal from '../util/sweetalert';
import footerStyles from './M5Footer.module.css';


const M5 = ({ createMode }: { createMode: boolean }) => {
    const [rows, setRows] = useState<M5Row[]>([]);
    const [indexedRows, setIndexedRows] = useState<{ [foilNumber: string]: M5Row }>();
    const [query, setQuery] = useState<string>('');
    const [activeFilters, setActiveFilters] = useState<number[]>([]);

    const [selectedFoilSide, setSelectedFoilSide] = useState<'left' | 'right'>('left');
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
    const [product, updateProduct, productRef, initialProductRef] = useProductModel<M5Product>();

    const { loading, reload, error } = useReloadable(async () => {
        const [inputBatches, existingOutputBatches, batchesWithCSFoils] = await fetchBatches(['^A-', '^P-', '^L-']);
        const outputBatches = excludeCurrentBatch(existingOutputBatches, productRef.current?.identifier);

        const rows: M5Row[] = resolveFoilAvailability<M5Row>(inputBatches, outputBatches, 'foils');
        const availableRows = rows.filter((row) => row.available);
        const detailedRows: M5Row[] = await resolveRowDetails(availableRows, M5_COLUMNS, inputBatches);
        const rowsWithCSFoils: M5Row[] = addCSFoilNumbers(detailedRows, batchesWithCSFoils);

        setRows(rowsWithCSFoils);
        setIndexedRows(indexRows(rowsWithCSFoils));
    }, []);

    const batchNumber = product?.identifier || '';
    const visibleRows = filterRows<M5Row, M5SearchContext>(rows, M5_FILTERS, M5_SEARCH, activeFilters, M5_PREPARE_SEARCH(query));
    const selectedLeftFoilRowKeys = product?.parameters?.L_foils || [];
    const selectedRightFoilRowKeys = product?.parameters?.R_foils || [];
    const selectedRowKeys = selectedLeftFoilRowKeys.concat(selectedRightFoilRowKeys);
    const selectedRows = filterSelectedRows(selectedRowKeys, indexedRows);
    const footerRows = getFooterRowsFromParameters(product?.parameters, selectedRows);

    const handleRowToggle = (_: any, toggledRowKey: string) => {
        if (selectedRowKeys.includes(toggledRowKey)) {
            // When deselecting a row, remove the foil reference from the parameters
            const updatedFooterRows = footerRows.map((row) => {
                if (row.leftFoilNumber === toggledRowKey) {
                    return {
                        ...row,
                        leftFoilNumber: '',
                        leftFoilBatchNumber: '',
                        leftFoilFrontLens: '',
                        leftFoilBackLens: '',
                    };
                }

                if (row.rightFoilNumber === toggledRowKey) {
                    return {
                        ...row,
                        rightFoilNumber: '',
                        rightFoilBatchNumber: '',
                        rightFoilFrontLens: '',
                        rightFoilBackLens: '',
                    }
                }

                return row;
            });

            updateProduct({
                parameters: getParametersFromFooterRows(updatedFooterRows),
            })
        }
        else if (indexedRows) {
            // When selecting a row, add the foil reference to currently selected spot (selectedRowIndex and selectedFoilSide)
            const footerRow = footerRows[selectedRowIndex];
            const foilRow = indexedRows[toggledRowKey];

            if (!foilRow) {
                return;
            }

            let updatedFooterRow;

            if (selectedFoilSide === 'left') {
                updatedFooterRow = {
                    ...footerRow,
                    leftFoilNumber: foilRow.foilNumber,
                    leftFoilBatchNumber: foilRow.batchNumber,
                    leftFoilFrontLens: foilRow.frontLensBlank || '',
                    leftFoilBackLens: foilRow.backLensBlank || '',
                };
            }
            else {
                updatedFooterRow = {
                    ...footerRow,
                    rightFoilNumber: foilRow.foilNumber,
                    rightFoilBatchNumber: foilRow.batchNumber,
                    rightFoilFrontLens: foilRow.frontLensBlank || '',
                    rightFoilBackLens: foilRow.backLensBlank || '',
                };
            }

            const updatedFooterRows = [...footerRows];
            updatedFooterRows[selectedRowIndex] = updatedFooterRow;

            updateProduct({
                parameters: getParametersFromFooterRows(updatedFooterRows),
            });

            // Jump to next spot
            if (selectedFoilSide === 'left') {
                setSelectedFoilSide('right');
            }
            else {
                setSelectedRowIndex(selectedRowIndex + 1);
                setSelectedFoilSide('left');
            }
        }
    }

    const handleFooterRowUpdate = (updatedFooterRows: M5FooterRow[]) => {
        updateProduct({
            parameters: getParametersFromFooterRows(updatedFooterRows),
        });
    }

    const updateBatchNumber = (newBatchNumber: string) => {
        updateProduct({
            identifier: newBatchNumber
        });
    }

    const handlePasteFoils = async (foilNumbers: string[]) => {
        const { dismiss, value } = await Swal.fire({
            title: 'Are the pasted foils for the left lens or the right lens?',
            icon: 'question',
            showCloseButton: true,
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonText: 'Left',
            confirmButtonText: 'Right',
            cancelButtonColor: '#3085d6',
            heightAuto: false,
        });

        const isLeft = dismiss === Swal.DismissReason.cancel;
        const isRight = value === true;

        if (!(isLeft || isRight) || !indexedRows) {
            return;
        }

        const [selectedRowKeys, unavailableRowKeys] = isLeft
            ? [selectedLeftFoilRowKeys, selectedRightFoilRowKeys]
            : [selectedRightFoilRowKeys, selectedLeftFoilRowKeys];
        const newSelectedRowKeys = await resolvePastedFoilNumbers(foilNumbers, selectedRowKeys, unavailableRowKeys, indexedRows);

        if (!newSelectedRowKeys) {
            return;
        }

        const updatedFooterRows = [...footerRows];
        for (let rowKeyIndex = 0; rowKeyIndex < newSelectedRowKeys.length; rowKeyIndex++) {
            const rowKey = newSelectedRowKeys[rowKeyIndex];
            const footerRow = footerRows[rowKeyIndex];
            const foilRow = indexedRows[rowKey] || { foilNumber: rowKey, batchNumber: 'unknown' };

            if (isLeft) {
                updatedFooterRows[rowKeyIndex] = {
                    ...footerRow,
                    leftFoilNumber: foilRow.foilNumber,
                    leftFoilBatchNumber: foilRow.batchNumber,
                    leftFoilFrontLens: foilRow.frontLensBlank || '',
                    leftFoilBackLens: foilRow.backLensBlank || '',
                };
            }
            else {
                updatedFooterRows[rowKeyIndex] = {
                    ...footerRow,
                    rightFoilNumber: foilRow.foilNumber,
                    rightFoilBatchNumber: foilRow.batchNumber,
                    rightFoilFrontLens: foilRow.frontLensBlank || '',
                    rightFoilBackLens: foilRow.backLensBlank || '',
                };
            }
        }

        updateProduct({
            parameters: getParametersFromFooterRows(updatedFooterRows),
        });
    }

    const handleAddRow = () => {
        updateProduct({
            parameters: getParametersFromFooterRows([...footerRows, getDefaultFooterRow()]),
        })
    }

    const handleClearRows = () => {
        updateProduct({
            parameters: getParametersFromFooterRows([]),
        })
    }

    if (error) {
        return (
            <ErrorMessage error={error} />
        )
    }

    return (
        <Modal
            loading={loading}
            onBeforeSubmit={() => M5_VALIDATE(product, createMode, initialProductRef.current)}
        >
            <header className={headerStyles.topHeader}>
                <HeaderField
                    id={'batchNumber'}
                    label={'Batch number'}
                    value={batchNumber}
                    onChange={updateBatchNumber}
                />
            </header>
            <header className={headerStyles.bottomHeader}>
                <Filter
                    query={query}
                    setQuery={setQuery}
                    filters={M5_FILTERS}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                />
                <HeaderButtons<M5Row>
                    columns={M5_COLUMNS}
                    rows={rows}
                    exportFilename={'Azumuta_export_M5'}
                    onPasteFoils={handlePasteFoils}
                    onReload={reload}
                />
            </header>
            <section className={styles.inputTableWrapper}>
                <div style={{ overflowX: 'scroll', width: '150%' }}>
                    <FoilTable<M5Row>
                        columns={M5_COLUMNS}
                        rows={visibleRows}
                        selectedRowKeys={selectedRowKeys}
                        sortable={true}
                        onRowToggle={handleRowToggle}
                    />
                </div>
            </section>
            <section className={styles.outputTableWrapper}>
                <M5Footer
                    rows={footerRows}
                    selectedFoilSide={selectedFoilSide}
                    selectedRowIndex={selectedRowIndex}
                    setSelectedFoilSide={setSelectedFoilSide}
                    setSelectedRowIndex={setSelectedRowIndex}
                    onUpdate={handleFooterRowUpdate}
                />
            </section>
            <footer className={footerStyles.footer}>
                <span className={footerStyles.footerArrow}>⤷</span>
                <button
                    className={footerStyles.footerButton}
                    onClick={handleAddRow}
                >
                    <span role="img" aria-label='Plus icon'>➕</span> Add row
                </button>
                <button
                    className={footerStyles.footerButton}
                    onClick={handleClearRows}
                >
                    Clear all rows
                </button>
            </footer>
        </Modal>
    );
}

export default M5;
