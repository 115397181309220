import React, { useMemo } from 'react';
import styles from './ChangedFoilsList.module.css';
import { diffArrays } from '../util/diffArrays';

type ChangedFoilsListProps = {
    initialFoils: string[],
    updatedFoils: string[],
}

const ChangedFoilsList = (props: ChangedFoilsListProps) => {
    const { initialFoils, updatedFoils } = props; 

    const diffedFoils = useMemo(() => diffArrays(initialFoils, updatedFoils), [initialFoils, updatedFoils]);
    let removedCount = 0;
    let addedCount = 0;
    for (const item of diffedFoils) {
        if (item.removed) {
            removedCount++;
        }
        if (item.added) {
            addedCount++;
        }
    }

    return (
        <>
            <ul className={styles.changedFoilsList}>
                {diffedFoils.map((item, keyIndex) => {
                    const { value, added, removed } = item;

                    return (
                        <li
                            key={keyIndex}
                            className={styles.changedFoilsItem}
                            style={{
                                textDecoration: removed ? 'line-through' : undefined,
                            }}
                        >
                            <span
                                style={{ backgroundColor: added ? '#e6ffed' : (removed ? '#ffeef0' : undefined) }}
                            >
                                {value}
                            </span>
                        </li>
                    );
                })}
            </ul>
            {(addedCount || removedCount) ?
                <p>
                    {removedCount ? removedCount + ' foils were removed' + (addedCount ? ' and ' : '') : ''}
                    {addedCount ? addedCount + ' foils were added' : ''}
                </p>
                :
                <p>No changes</p>
            }
        </>
    )
}

export default ChangedFoilsList;