import React, { useState } from 'react';
import styles from './TableAnswer.module.css';
import {FOIL_NUMBER_COLUMNS} from '../configs/sample-history.config';

type TableRowAnswerProps = {
    table: any[][],
    currentFoilNumber: string,
}

const TableAnswer = (props: TableRowAnswerProps) => {
    const { table, currentFoilNumber } = props;

    const [showFullTable, setShowFullTable] = useState(false);

    const foilNumberColumnIndex = getFoilNumberColumnIndex(table);
    const isFilterable = foilNumberColumnIndex > -1;
    const filteredTable = (!isFilterable || showFullTable)
        ? table
        : maybeFilterTableRows(table, foilNumberColumnIndex, currentFoilNumber);
    const [headerRow, ...dataRows] = filteredTable;

    return (
        <div className={styles.wrapper}>
            <div className={styles.answerTableWrapper}>
                <table className={styles.answerTable}>
                    <thead>
                        <tr>
                            {headerRow.map((cell, cellIndex) => {
                                return (
                                    <th key={cellIndex}>{cell}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {dataRows.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => {
                                        const isFoilNumber = cell === currentFoilNumber;

                                        return (
                                            <td key={cellIndex}>
                                                {isFoilNumber
                                                    ? <strong>{cell}</strong>
                                                    : cell
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {isFilterable &&
                <div className={styles.filter}>
                    <span>↳</span>
                    <span
                        className={styles.filterButton}
                        onClick={() => setShowFullTable((on) => !on)}
                    >
                        {showFullTable ? 'Hide' : 'Show'} full table
                    </span>
                </div>
            }
        </div>
    )
}

export default TableAnswer;

const getFoilNumberColumnIndex = (table: any[][]) =>
    table[0].findIndex((cell) => FOIL_NUMBER_COLUMNS.includes(cell));

const maybeFilterTableRows = (table: any[][], foilNumberColumnIndex: number, currentFoilNumber: string): any[][] => {
    const [headerRow, ...dataRows] = table;
    const foilRow = dataRows.find((row) => row[foilNumberColumnIndex] === currentFoilNumber);

    if (!foilRow) {
        return table;
    }

    return [headerRow, foilRow];
}