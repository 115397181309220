import { BaseRow, DetailedColumn, BatchWithFoils } from "../modals/common";
import { RetrieveProductResponse, RetrieveProductRecording, RetrieveProductRecordingInstruction, RetrieveProductRecordingAnswer } from "../azumuta";
import { retrieveProduct } from "../azumuta-api";


/**
 * Generates a list of rows, one for each foil of every input batch, and resolves their availability based on the existing output batches
 * @param inputBatches  
 * @param outputBatches 
 * @param foilsProp     Which property of the product parameters the foils are defined on
 */
export const resolveFoilAvailability = <R extends BaseRow>(inputBatches: BatchWithFoils[], outputBatches: BatchWithFoils[], foilsProp: 'foils' | 'cs_foils') => {
    const rows: R[] = [];
    const rowMap: { [foilNumber: string]: R } = {};

    for (const batch of inputBatches) {
        const foils = batch[foilsProp];

        if (!Array.isArray(foils)) {
            continue;
        }

        for (const foil of foils) {
            const row: BaseRow = {
                key: foil,
                batchNumber: batch.batchNumber,
                foilNumber: foil,
                available: true,
            };

            rows.push(row as R);
            rowMap[foil] = row as R;
        }
    }

    for (const batch of outputBatches) {
        const foils = batch[foilsProp];

        if (!Array.isArray(foils)) {
            continue;
        }

        for (const foil of foils) {
            const row = rowMap[foil];

            // !row && console.log(`No row for ${foil}`);

            if (row) {
                row.available = false;
                row.nextBatchNumber = batch.batchNumber;
            }
        }
    }

    return rows;
}

const findInstruction = (recording: RetrieveProductRecording, instructionUuid: string): RetrieveProductRecordingInstruction | undefined => {
    for (const workstation of recording.data) {
        for (const instruction of workstation.instructions) {
            if (instruction.uuid === instructionUuid) {
                return instruction;
            }
        }
    }
}

const getTableAnswers = (response: RetrieveProductResponse, workinstructionId: string, instructionUuid: string): RetrieveProductRecordingAnswer | undefined => {
    const recording = response.recordings
        ?.find((recording: RetrieveProductRecording) => recording.workInstruction.id === workinstructionId);

    if (!recording) {
        return;
    }

    const instruction = findInstruction(recording, instructionUuid);

    if (!instruction) {
        return;
    }

    return instruction.answer;
}

type ResolvedAnswers = { [key: string]: RetrieveProductRecordingAnswer };

const tableCoordsCache: { [key: string]: { foilColumnIndex: number, answerColumnIndex: number } } = {};

const addRowDetails = <R extends BaseRow>(rows: R[], columns: DetailedColumn<R>[], batchNumber: string, details: ResolvedAnswers): void => {
    for (const row of rows) {
        if (row.batchNumber !== batchNumber) {
            continue;
        }

        for (const column of columns) {
            const { prop, workinstructionId, instructionUuid, foilColumn, answerColumn } = column;

            if (!prop) {
                continue;
            }

            const tableCoordsKey = workinstructionId + '§' + instructionUuid + '§' + foilColumn + '§' + answerColumn;
            const answer = details[workinstructionId + '%' + instructionUuid];
            const table = answer?.value as any[][];

            if (answer && answer.type === 'table' && table.length > 1) {
                let tableCoords = tableCoordsCache[tableCoordsKey];

                if (!tableCoords) {
                    const foilColumnIndex = table[0].indexOf(foilColumn);
                    const answerColumnIndex = table[0].indexOf(answerColumn);

                    // console.log({ prop, workinstructionId, instructionUuid, foilColumnIndex, answerColumnIndex });

                    tableCoords = tableCoordsCache[tableCoordsKey] = { foilColumnIndex, answerColumnIndex };
                }

                const { foilColumnIndex, answerColumnIndex } = tableCoords;

                // console.log({tableCoords});

                if (foilColumnIndex === -1 || answerColumnIndex === -1) {
                    continue;
                }

                const foilRow = table.find((tableRow) => tableRow[foilColumnIndex] === row.foilNumber);

                if (!foilRow) {
                    continue;
                }

                const value = foilRow[answerColumnIndex];

                if (value === undefined) {
                    continue;
                }

                // @ts-ignore
                row[prop] = value;
            }
        }
    }
};

type AnswerCoords = { workinstructionId: string, instructionUuid: string };
type AnswerCoordsMap = { [key: string]: AnswerCoords };

const getInterestedAnswerCoords = (columns: DetailedColumn<any>[]): AnswerCoords[] => {
    const interestedAnswerCoordsMap: AnswerCoordsMap = {};

    for (const column of columns) {
        const { workinstructionId, instructionUuid } = column;

        if (workinstructionId && instructionUuid) {
            interestedAnswerCoordsMap[workinstructionId + '%' + instructionUuid] = { workinstructionId, instructionUuid };
        }
    }

    return Object.values(interestedAnswerCoordsMap);
}

const collectAnswers = (productResponse: RetrieveProductResponse, answerCoords: AnswerCoords[]): ResolvedAnswers => {
    const collectedAnswers: ResolvedAnswers = {};

    for (const coords of answerCoords) {
        const { workinstructionId, instructionUuid } = coords;
        const answer = getTableAnswers(productResponse, workinstructionId, instructionUuid);

        if (answer) {
            collectedAnswers[workinstructionId + '%' + instructionUuid] = answer;
        }
    }

    return collectedAnswers;
}

/**
 * Add all details defined in the specified columns to the specified rows
 * These details are fetched from the right product recordings defined by the columns automatically
 * @param rows 
 * @param columns 
 */
export const resolveRowDetails = async <R extends BaseRow>(rows: R[], columns: DetailedColumn<R>[], inputBatches: BatchWithFoils[]): Promise<R[]> => {
    const batchNumbers = new Set<string>();

    for (const row of rows) {
        batchNumbers.add(row.batchNumber);
    }

    const activeOrCompleteBatchNumbers = [];
    for (const batchNumber of batchNumbers) {
        const batch = inputBatches.find((batch) => batch.batchNumber === batchNumber);

        if (batch && batch.status !== 'NOT ACTIVE') {
            activeOrCompleteBatchNumbers.push(batchNumber);
        }
    }

    const promises = activeOrCompleteBatchNumbers.map((batchNumber) => retrieveProduct(batchNumber));
    const interestedAnswerCoords: AnswerCoords[] = getInterestedAnswerCoords(columns);

    for (const promise of promises) {
        promise.then((productResponse: RetrieveProductResponse) => {
            const batchNumber = productResponse.identifier;
            const interestedAnswers = collectAnswers(productResponse, interestedAnswerCoords);

            addRowDetails(rows, columns, batchNumber, interestedAnswers);
        });
    }

    return Promise.all(promises)
        .then(() => rows);
}

export const addCSFoilNumbers = <R extends {foilNumber: string, csFoilNumber: string}>(rows: R[], batches: BatchWithFoils[]): R[] => {
    const rowMap: {[foilNumber: string]: R} = {};

    for (const row of rows) {
        rowMap[row.foilNumber] = row;
    }

    for (const batch of batches) {
        const {foils, cs_foils} = batch;

        if (!foils?.length || !cs_foils?.length) {
            continue;
        }

        for (let foilIndex = 0; foilIndex < foils.length; foilIndex++) {
            const foilNumber = foils[foilIndex];
            const csFoilNumber = cs_foils[foilIndex];

            const matchingRow: R = rowMap[foilNumber];

            if (matchingRow && csFoilNumber) {
                matchingRow.csFoilNumber = csFoilNumber;
            }
        }
    }

    return rows;
};

export const indexRows = <R extends BaseRow>(rows: R[]): {[foilNumber: string]: R} => {
    const index: {[foilNumber: string]: R} = {};

    for (const row of rows) {
        index[row.foilNumber] = row;
    }

    return index;
}

export const filterSelectedRows = <R extends BaseRow>(selectedKeys: string[], indexedRows?: { [foilNumber: string]: R }): R[]  => {
    if (!indexedRows) {
        return [];
    }

    return selectedKeys
        .map((key: string) => indexedRows[key] || { key, batchNumber: 'unknown', foilNumber: key })
}
