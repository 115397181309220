import React from 'react';
import { RetrieveProductRecording } from '../azumuta';
import InstructionOverview from './InstructionOverview';
import styles from './RecordingOverview.module.css';

type RecordingOverviewProps = {
    recording: RetrieveProductRecording,
    currentFoilNumber: string,
}

const RecordingOverview = (props: RecordingOverviewProps) => {
    const { recording, currentFoilNumber } = props;

    const isFinished = recording.status === 'FINISHED';

    const instructions = [];
    for (const worker of recording.data) {
        instructions.push(...worker.instructions);
    }

    return (
        <article className={styles.recording}>
            <header className={styles.recordingHeader}>
                <strong>{recording.workInstruction.name}</strong>
                {isFinished &&
                <span className={styles.finishedBadge}>✔ Finished</span>
                } 
            </header>
            <main className={styles.recordingContent}>
                {instructions.map((instruction) => {

                    return (
                        <InstructionOverview
                            key={instruction.uuid}
                            instruction={instruction}
                            currentFoilNumber={currentFoilNumber}
                        />
                    )
                })}
            </main>
        </article>
    );
}

export default RecordingOverview;
