import React, { useState } from 'react';
import { RetrieveProductResponse } from '../azumuta';
import RecordingOverview from './RecordingOverview';
import styles from './BatchOverview.module.css';
import { getApiDomain } from '../azumuta-api';

type BatchOverviewProps = {
    batch: RetrieveProductResponse,
    currentFoilNumber: string,
}

const BatchOverview = (props: BatchOverviewProps) => {
    const { batch, currentFoilNumber } = props;

    const [isOpen, setIsOpen] = useState(false); // !isInactive

    const isInactive = batch.status === 'NOT ACTIVE';
    const isArchived = !!batch.archived;
    const hasMultilineNotes = batch.notes?.includes('\n');
    const hasAttachments = !!batch.attachments?.length;
    const apiDomain = getApiDomain();

    return (
        <section className={styles.batch}>
            <header className={styles.batchHeader}>
                <div className={styles.batchHeaderChevron}>
                    <button
                        disabled={isInactive}
                        onClick={() => setIsOpen((open) => !open)}
                    >
                        {isOpen ? '▼' : '▶'}
                    </button>
                </div>
                <div className={styles.batchHeaderTitle}>
                    <strong>Batch {batch.identifier}</strong>
                    {isArchived &&
                        <span className={styles.archivedBadge}>
                            <span role="img" aria-label="Archive icon">🗄️</span> Archived
                        </span>
                    }
                    {isInactive &&
                        <div>Not active</div>
                    }
                    {batch.notes &&
                        <div>
                            Notes: {hasMultilineNotes ? <div className={styles.batchNotes}>{batch.notes}</div> : batch.notes}
                        </div>
                    }
                </div>
                {hasAttachments &&
                    <div className={styles.batchHeaderAttachments}>
                        {batch.attachments?.map((attachment) => {
                            const attachmentUrl = `${apiDomain}${attachment.url}`;

                            return (
                                <a
                                    key={attachment.url}
                                    href={attachmentUrl}
                                    className={styles.batchHeaderAttachment}
                                    download={attachment.filename}
                                >
                                    <span role="img" aria-label="Attachment icon" className={styles.batchHeaderAttachmentIcon}>📎</span>
                                    <span className={styles.batchHeaderAttachmentLabel}>{attachment.filename}</span>
                                </a>
                            )
                        })}
                    </div>
                }
            </header>
            {isOpen &&
                <main className={styles.batchContent}>
                    {batch.recordings?.map((recording) => {
                        return (
                            <RecordingOverview
                                key={recording.url}
                                recording={recording}
                                currentFoilNumber={currentFoilNumber}
                            />
                        )
                    })}
                </main>
            }
        </section>
    );
}

export default BatchOverview;
