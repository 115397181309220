import { BaseRow, RowFilter, Column } from "../modals/common";

/**
 * Filter rows based on the specified filters and query
 * If there are no active filters or no query defined, then all rows are returned
 * @param rows 
 * @param filters 
 * @param searchFn      
 * @param activeFilters 
 * @param query 
 */
export const filterRows = <R extends BaseRow, T extends Object>(
    rows: R[],
    filters: RowFilter<R>[],
    searchFn: (row: R, searchContext: T) => boolean,
    activeFilters: number[],
    searchContext?: T,
): R[] => {
    if (activeFilters.length === 0 && !searchContext) {
        return rows;
    }

    for (let filterIndex = 0; filterIndex < filters.length; filterIndex++) {
        if (activeFilters.includes(filterIndex)) {
            const { callback } = filters[filterIndex];

            rows = rows.filter(callback);
        }
    }

    if (searchContext) {
        rows = rows.filter((row) => searchFn(row, searchContext));
    }

    return rows;
}

export const isEmpty = (value: any): boolean => {
    if (value === undefined) {
        return true;
    }

    if (typeof value === 'string') {
        return value.length === 0;
    }

    return false;
}

export const isEqualTo = (value: any, check: any): boolean => {
    if (typeof value === 'string' && typeof check === 'string') {
        return value.toLowerCase() === check.toLowerCase();
    }

    return false;
}

type FoilNumberSearchContext = {
    foilQuery?: string,
    alternativeFoilQuery?: string,
}
export const makeFoilNumberSearchContext = (query: string): FoilNumberSearchContext | undefined => {
    // Search with and without dash in foil number automatically (fn/fn-/fc/fc-)
    switch(query.slice(0, 2)) {
        case 'fn': {
            return {
                foilQuery: query,
                alternativeFoilQuery: query[2] === '-' ? 'fn' + query.slice(3) : 'fn-' + query.slice(2),
            }
        }
        case 'fc': {
            return {
                foilQuery: query,
                alternativeFoilQuery: query[2] === '-' ? 'fc' + query.slice(3) : 'fc-' + query.slice(2),
            }
        }
    }
}

export const matchesFoilNumberSearchContext = (searchContext: FoilNumberSearchContext, foilNumber: string): boolean => {
    if (!foilNumber) {
        return false;
    }

    const {foilQuery, alternativeFoilQuery} = searchContext;
    const rowFoilNumber = foilNumber.toLowerCase();

    if (foilQuery && rowFoilNumber.startsWith(foilQuery)) {
        return true;
    }

    if (alternativeFoilQuery && rowFoilNumber.startsWith(alternativeFoilQuery)) {
        return true;
    }

    return false;
}

export const getSearchableProps = <R extends BaseRow>(columns: Column<R>[]): (keyof R)[] => {
    return columns
        .filter(column => column.searchable)
        .map((column) => column.prop);
}

export const matchesAnyProperty = <R extends BaseRow>(query: string, row: R, props: (keyof R)[]): boolean => {
    for (const prop of props) {
        const value: any = row[prop];

        if (typeof value === 'string' && value.toLowerCase().includes(query)) {
            return true;
        }
    }

    return false;
}