import React from 'react';
import { Row, DetailedColumn, RowFilter } from "../modals/common";
import { isEmpty, isEqualTo, makeFoilNumberSearchContext, matchesFoilNumberSearchContext, matchesAnyProperty, getSearchableProps } from "../util/filter";
import { SerializableProduct } from "../azumuta";
import { swalConfirm, swalError } from "../util/sweetalert";
import { arraysEqual } from "../util/diffArrays";
import ChangedFoilsList from '../components/ChangedFoilsList';

export interface M3LRow extends Row {
    batchNumber: string,
    foilNumber: string,
    available: boolean,
    rubbingOKNOK?: string,
    rubbingRemarks?: string,
    spinCoatPIOKNOK?: string,
    spinCoatRemarks?: string,
    nextBatchNumber?: string,
}

// Only difference between M3L_FOILS_COLUMNS and M3L_CS_FOILS_COLUMNS are the `workinstructionId`'s and `instructionUuid`'s

export const M3L_FOILS_COLUMNS: DetailedColumn<M3LRow>[] = [
    {
        title: 'Batch number',
        prop: 'batchNumber',
        searchable: true,
    },
    {
        title: 'Foil number',
        prop: 'foilNumber',
    },
    {
        title: 'Rubbing OK/NOK',
        prop: 'rubbingOKNOK',
        workinstructionId: 'tPc62tNrfAWdWbYyQ',
        instructionUuid: '984cd3e7-4abe-4d8d-9760-8ca817e7c4d8',
        foilColumn: 'Foil name',
        answerColumn: 'OK/NOK',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'rubbingRemarks',
        workinstructionId: 'tPc62tNrfAWdWbYyQ',
        instructionUuid: '984cd3e7-4abe-4d8d-9760-8ca817e7c4d8',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'Spin-coat PI OK/NOK',
        prop: 'spinCoatPIOKNOK',
        workinstructionId: '3jBB767mbwwJ9bGBE',
        instructionUuid: '499eb744-147a-453f-a0d8-cbcf6c64accc',
        foilColumn: 'Foil name',
        answerColumn: 'OK/NOK',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'spinCoatRemarks',
        workinstructionId: '3jBB767mbwwJ9bGBE',
        instructionUuid: '499eb744-147a-453f-a0d8-cbcf6c64accc',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    // {
    //     title: 'M3 batch number',
    //     prop: 'nextBatchNumber',
    // },
];

export const M3L_CS_FOILS_COLUMNS: DetailedColumn<M3LRow>[] = [
    {
        title: 'Batch number',
        prop: 'batchNumber',
        searchable: true,
    },
    {
        title: 'Foil number',
        prop: 'foilNumber',
        searchable: true,
    },
    {
        title: 'Rubbing OK/NOK',
        prop: 'rubbingOKNOK',
        workinstructionId: 'MBtZ92z3eBWCaWYcw',
        instructionUuid: '7a12f747-c306-4c1a-908a-9025b77f4a12',
        foilColumn: 'Foil name',
        answerColumn: 'OK/NOK',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'rubbingRemarks',
        workinstructionId: 'MBtZ92z3eBWCaWYcw',
        instructionUuid: '7a12f747-c306-4c1a-908a-9025b77f4a12',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    {
        title: 'Spin-coat PI OK/NOK',
        prop: 'spinCoatPIOKNOK',
        workinstructionId: 'ubKDu9hJMaqWo8fbL',
        instructionUuid: '1aa76f1c-b752-4d93-aa3d-797623a76472',
        foilColumn: 'Foil name',
        answerColumn: 'OK/NOK',
        searchable: true,
    },
    {
        title: 'Remarks',
        prop: 'spinCoatRemarks',
        workinstructionId: 'ubKDu9hJMaqWo8fbL',
        instructionUuid: '1aa76f1c-b752-4d93-aa3d-797623a76472',
        foilColumn: 'Foil name',
        answerColumn: 'Remarks',
        searchable: true,
    },
    // {
    //     title: 'M3 batch number',
    //     prop: 'nextBatchNumber',
    // },
];

export type M3LSearchContext = {
    query: string,
    foilQuery?: string,
    alternativeFoilQuery?: string,
}

export const M3L_PREPARE_SEARCH = (query: string): M3LSearchContext | undefined => {
    if (!query) {
        return undefined;
    }

    const context: M3LSearchContext = {
        query: query.toLowerCase(),
    };

    Object.assign(context, makeFoilNumberSearchContext(context.query));

    return context;
}

const searchableProps = getSearchableProps(M3L_FOILS_COLUMNS);
export const M3L_SEARCH = (row: M3LRow, context: M3LSearchContext): boolean => {
    const { query } = context;

    if (matchesFoilNumberSearchContext(context, row.foilNumber)) {
        return true;
    }

    if (matchesAnyProperty<M3LRow>(context.query, row, searchableProps)) {
        return true;
    }

    return row.foilNumber.toLowerCase().startsWith(query);
}

export const M3L_FILTERS: RowFilter<M3LRow>[] = [
    {
        label: 'Hide all NOK',
        callback: (row: M3LRow) => !isEqualTo(row.rubbingOKNOK, 'NOK') && !isEqualTo(row.spinCoatPIOKNOK, 'NOK'),
    },
    {
        label: 'Hide items with unfilled Rubbing Quality Check',
        callback: (row: M3LRow) => !isEmpty(row.rubbingOKNOK), // todo
    },
];

export const M3L_VALIDATE = async (product: SerializableProduct | undefined, createMode: boolean, initialProduct?: SerializableProduct | null): Promise<boolean> => {
    if (!product) {
        return false;
    }

    const foils = product.parameters?.foils;
    const cs_foils = product.parameters?.cs_foils;

    if (foils?.length !== cs_foils?.length) {
        await swalError('Amount of selected foils doesn\'t match amount of selected counter substrate foils');

        return false;
    }

    if (createMode) {
        const isEmpty = !foils?.length && !cs_foils?.length;
        const { dismiss } = await swalConfirm(isEmpty
            ? 'Do you want to create an EMPTY batch?'
            : 'Do you want to create a batch?'
        );

        if (dismiss) {
            return false;
        }
    }
    else if (initialProduct) {
        const initialFoils = initialProduct.parameters?.foils || [];
        const initialCSFoils = initialProduct.parameters?.cs_foils || [];

        if (arraysEqual(foils, initialFoils) && arraysEqual(cs_foils, initialCSFoils)) {
            const { dismiss } = await swalConfirm('Selected foils haven\'t changed. Continue anyway?');
            if (dismiss) {
                return false;
            }
        }
        else {
            const { dismiss } = await swalConfirm('Do you want to update this batch?', (
                <>
                    <p style={{ textAlign: 'left' }}>Foils:</p>
                    <ChangedFoilsList
                        initialFoils={initialFoils}
                        updatedFoils={foils}
                    />
                    <p style={{ textAlign: 'left' }}>Counter substrate foils:</p>
                    <ChangedFoilsList
                        initialFoils={initialCSFoils}
                        updatedFoils={cs_foils}
                    />
                </>
            ))

            if (dismiss) {
                return false;
            }
        }
    }
    else {
        await swalError('Something went wrong');

        return false;
    }

    return true;
};