import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import styles from './FoilTable.module.css';
import { Row, Column, BaseRow, ColumnProp } from '../modals/common';
import SortButton from './SortButton';
import orderBy from 'lodash.orderby';
import { ListIteratee } from 'lodash';


type FoilTableProps<R extends Row> = {
    columns: Column<R>[],
    rows: R[],
    selectedRowKeys: string[],
    sortable?: boolean,
    onRowToggle: (newSelectedRowKeys: string[], toggledRowKey: string) => void,
}
type SortColumn<R> = { prop?: ColumnProp<R>, direction?: 'asc' | 'desc' }

function FoilTable<R extends BaseRow>(props: FoilTableProps<R>) {
    const { columns, rows, selectedRowKeys, sortable = false, onRowToggle } = props;

    const [sortColumn, setSortColumn] = useState<SortColumn<R>>({
        prop: 'batchNumber' as ColumnProp<R>,
        direction: 'asc'
    });
    const { prop: sortProp, direction: sortDirection } = sortColumn;

    const handleSortClick = (prop: ColumnProp<R>) =>
        setSortColumn((currentSortColumn) => ({
            prop,
            direction: (currentSortColumn.prop !== prop || currentSortColumn.direction === 'desc') ? 'asc' : 'desc'
        }));

    const handleInputToggle = (rowKey: string) => {
        if (selectedRowKeys.includes(rowKey)) {
            onRowToggle(selectedRowKeys.filter((key) => key !== rowKey), rowKey);
        }
        else {
            onRowToggle([...selectedRowKeys, rowKey], rowKey);
        }
    }

    const orderedRows = useMemo(
        () => sortable
            ? orderRows(rows, columns, sortProp, sortDirection)
            : rows,
        [sortable, rows, columns, sortProp, sortDirection],
    )

    const columnWidth = (100 / columns.length) + '%';
    const rowKeyCounts: { [key: string]: number } = {};

    return (
        <table className={styles.table}>
            <colgroup>
                <col style={{ width: '32px' }} />
                <col style={{ width: '48px' }} />
                {columns.map((column) => (
                    <col
                        key={column.prop as string}
                        style={{ width: columnWidth }}
                    />
                ))}
            </colgroup>
            <thead>
                <tr className={styles.tableHeadRow}>
                    <th className={styles.tableHeadCell} />
                    <th className={styles.tableHeadCell} />
                    {columns.map((column) => {
                        const { title, prop } = column;
                        const mode = (prop === sortProp && sortDirection) ? sortDirection : 'none';

                        return (
                            <th
                                key={prop as string}
                                className={styles.tableHeadCell}
                            >
                                <div className={styles.tableHeadCellContent}>
                                    {title}
                                    {sortable &&
                                        <SortButton
                                            mode={mode}
                                            onClick={() => handleSortClick(prop)}
                                        />
                                    }
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {orderedRows.map((row, rowIndex) => {
                    const isSelected = selectedRowKeys.includes(row.key);
                    const cells = [
                        <td
                            key={'checkbox'}
                            className={styles.tableCheckboxCell}
                        >
                            <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => handleInputToggle(row.key)}
                            />
                        </td>,
                        <td
                            key={'order'}
                            className={styles.tableOrderCell}
                        >
                            {rowIndex + 1}.
                        </td>
                    ];

                    for (const column of columns) {
                        const { prop } = column;

                        cells.push(
                            <td
                                key={prop as string}
                                className={styles.tableCell}
                            >
                                {row[prop]}
                            </td>
                        )
                    }

                    // Some row.keys aren't unique
                    const rowKeyCount = (rowKeyCounts[row.key] === undefined)
                        ? (rowKeyCounts[row.key] = 0)
                        : ++rowKeyCounts[row.key];

                    return (
                        <tr
                            key={row.key + '-' + rowKeyCount}
                            className={classNames(styles.tableRow, isSelected && styles.tableRowSelected)}
                            // @ts-ignore Don't select text on double click
                            onMouseDown={(e) => e.detail === 2 && e.preventDefault()}
                            onDoubleClick={() => handleInputToggle(row.key)}
                        >
                            {cells}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default FoilTable;

const orderRows = <R extends BaseRow>(rows: R[], columns: Column<R>[], prop?: ColumnProp<R>, direction?: 'asc' | 'desc'): R[] => {
    if (!prop || !direction) {
        return rows;
    }

    if (prop === 'batchNumber') {
        return orderBy(rows, ['batchNumber', 'foilNumber'], [direction, 'asc']);
    }
    else if (prop === 'foilNumber') {
        return orderBy(rows, ['foilNumber'], [direction]);
    }

    let iteratee: ListIteratee<R> = prop;
    const column = columns.find((column) => column.prop === prop);

    if (column) {
        if (!column.propType || column.propType === 'string') {
            iteratee = (row: R) => {
                const value = row[prop];

                return (typeof value === 'string'
                    ? value.trim().toLowerCase()
                    : value) || undefined;
            };
        }
        else if (column.propType === 'number') {
            iteratee = (row: R) => {
                const value = row[prop];

                return typeof value === 'string'
                    ? value.length ? parseFloat(value.replace(',', '.')) : undefined
                    : value;
            }
        }
    }

    const iteratees = [iteratee, 'batchNumber', 'foilNumber'];
    const orders: ('asc' | 'desc')[] = [direction, 'asc', 'asc'];

    return orderBy(rows, iteratees, orders);
}
