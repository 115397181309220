import React from 'react';
import styles from './HeaderField.module.css';

type HeaderFieldProps = {
    id: string,
    label: string,
    value: string,
    onChange: (newValue: string) => void,
}

const HeaderField = (props: HeaderFieldProps) => {
    const { id, label, value, onChange } = props;

    return (
        <label
            htmlFor={id}
            className={styles.field}
        >
            <span className={styles.fieldLabel}>{label}:</span>
            <input
                id={id}
                className={styles.fieldInput}
                type={'text'}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </label>
    )
}

export default HeaderField;