import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import M2 from './modals/M2';
import M3N from './modals/M3N';
import M3CS from './modals/M3CS';
import M3L from './modals/M3L';
import M4 from './modals/M4';
import M5 from './modals/M5';
import queryString from 'query-string';
import { setApiDomain, setApiKey } from './azumuta-api';
import SampleHistory from './sample-history/SampleHistory';

/* eslint-disable no-restricted-globals */
const pathname = location!.pathname;
const query = queryString.parse(location.search);

const referrer = document.referrer && new URL(document.referrer);
const domain = (query.domain as string | undefined) || (referrer && `${referrer.hostname}${referrer.port ? ':' + referrer.port : ''}`);
const apiKey = query.apiKey as string | undefined;
const createMode = query.createMode === 'true';

if (domain) {
  const protocol = domain.indexOf('localhost') > -1 ? 'http://' : 'https://';
  setApiDomain(protocol + domain);
}

if (apiKey) {
  setApiKey(apiKey);
}

const endpoints: { [path: string]: React.ComponentType<{createMode: boolean}> } = {
  '/m2/': M2,
  '/m3n/': M3N,
  '/m3cs/': M3CS,
  '/m3l/': M3L,
  '/m4/': M4,
  '/m5/': M5,
  '/sample-history/': SampleHistory,
}

const component = endpoints[pathname];
document.title = pathname;

ReactDOM.render(
  <App
    component={component}
    endpoints={Object.keys(endpoints)}
    createMode={createMode}
    apiKey={apiKey}
  />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
