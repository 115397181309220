import { useState, useEffect, useCallback, useRef, RefObject } from "react"
import { SerializableProduct, SerializableProductUpdate } from "../azumuta";

/**
 * `useProductModel` abstracts away the product syncing between the modal inside Azumuta and this application inside an iframe
 * It has a similar signature to the `useState` hook, only that updates can be partial like the `setState` function in class components
 * It also exposes the updated product and the original product as a ref
 */
export const useProductModel = <P = SerializableProduct>(): [P | undefined, (productUpdate: SerializableProductUpdate) => void, RefObject<P | undefined>, RefObject<P | undefined>] => {
    const [product, setProduct] = useState<P>();
    const productRef = useRef<P | undefined>();
    const initialProductRef = useRef<P | undefined>();

    useEffect(() => {
        // @ts-ignore
        const Azumuta = window.Azumuta;

        if (typeof Azumuta !== undefined) {
            Azumuta.getProduct()
                .then((product: P) => {
                    setProduct(product);
                    initialProductRef.current = product;
                    productRef.current = product;
                })
                .catch((e: any) => console.error(e));
        }
    }, []);

    const updateProduct = useCallback((productUpdate: SerializableProductUpdate) => {
        // @ts-ignore
        const Azumuta = window.Azumuta;

        if (typeof Azumuta !== undefined) {
            Azumuta.updateProduct(productUpdate);
            setProduct((product) => {
                const updatedProduct = Object.assign({}, product, productUpdate);
                productRef.current = updatedProduct;

                return updatedProduct;
            });
        }
    }, []);

    return [product, updateProduct, productRef, initialProductRef];
}