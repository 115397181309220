import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import styles from '../components/FoilTable.module.css';
import TableInput from '../components/TableInput';
import classNames from 'classnames';
import { useDebouncedCallback } from '../util/useDebouncedCallback';

export type M5FooterRow = {
    frameNumber: string,
    remarks: string,
    leftFoilBatchNumber: string,
    leftFoilNumber: string,
    leftFoilFrontLens: string,
    leftFoilBackLens: string,
    rightFoilBatchNumber: string,
    rightFoilNumber: string,
    rightFoilFrontLens: string,
    rightFoilBackLens: string,
}

type M5FooterColumn = {
    title: string,
    prop: Extract<keyof M5FooterRow, string>,
    editable?: boolean,
    foilSide?: 'left' | 'right',
}

const M5_FOOTER_COLUMNS: M5FooterColumn[] = [
    {
        title: 'Frame number',
        prop: 'frameNumber',
        editable: true,
    },
    {
        title: 'Remarks',
        prop: 'remarks',
        editable: true,
    },
    {
        title: 'Foil number',
        prop: 'leftFoilNumber',
        foilSide: 'left',
    },
    {
        title: 'Batch number',
        prop: 'leftFoilBatchNumber',
        foilSide: 'left',
    },
    {
        title: 'Front lens',
        prop: 'leftFoilFrontLens',
        foilSide: 'left',
    },
    {
        title: 'Back lens',
        prop: 'leftFoilBackLens',
        foilSide: 'left',
    },
    {
        title: 'Foil number',
        prop: 'rightFoilNumber',
        foilSide: 'right',
    },
    {
        title: 'Batch number',
        prop: 'rightFoilBatchNumber',
        foilSide: 'right',
    },
    {
        title: 'Front lens',
        prop: 'rightFoilFrontLens',
        foilSide: 'right',
    },
    {
        title: 'Back lens',
        prop: 'rightFoilBackLens',
        foilSide: 'right',
    },
];


type M5FooterProps = {
    rows: M5FooterRow[],
    selectedFoilSide: 'left' | 'right',
    selectedRowIndex: number,
    onUpdate: (updatedRows: M5FooterRow[]) => void,
    setSelectedFoilSide: Dispatch<SetStateAction<'left' | 'right'>>,
    setSelectedRowIndex: Dispatch<SetStateAction<number>>
}

type LocalInputValue = {
    rowIndex: number,
    columnProp: string,
    value: string,
}

const M5Footer = (props: M5FooterProps) => {
    const { rows, onUpdate } = props;
    const { selectedFoilSide, selectedRowIndex, setSelectedFoilSide, setSelectedRowIndex } = props;

    const headerGroups = getHeaderGroups(M5_FOOTER_COLUMNS);
    const columnWidth = (100 / M5_FOOTER_COLUMNS.length) + '%';

    const [localInputValue, setLocalInputValue] = useState<LocalInputValue | undefined>();
    const onUpdateDebounced = useDebouncedCallback(onUpdate, 300);

    const handleInputChange = (rowIndex: number, columnProp: string, newValue: string) => {
        const updatedRows = [...rows];

        updatedRows[rowIndex] = {
            ...rows[rowIndex],
            [columnProp]: newValue,
        }

        setLocalInputValue({ rowIndex, columnProp, value: newValue });
        onUpdateDebounced(updatedRows);
    }

    const handleRemoveRow = (rowIndex: number) => {
        const updatedRows = [...rows];
        updatedRows.splice(rowIndex, 1);

        setLocalInputValue(undefined);
        onUpdate(updatedRows);
    }

    useEffect(() => {
        setLocalInputValue(undefined);
    }, [rows.length]);

    return (
        <table className={styles.table}>
            <colgroup>
                <col style={{ width: 32 }} />
                <col style={{ width: 48 }} />
                {M5_FOOTER_COLUMNS.map((column, columnIndex) => {
                    const isFirstColumn = columnIndex === 0;
                    const isLastColumn = columnIndex === M5_FOOTER_COLUMNS.length - 1;
                    const isFirstOfGroup = headerGroups.some((group) => group.start === columnIndex);
                    const isLastOfGroup = headerGroups.some((group) => group.stop === columnIndex);

                    return (
                        <col
                            key={column.prop as string}
                            style={{
                                width: columnWidth,
                                borderLeft: isFirstOfGroup && !isFirstColumn ? '2px solid #e5e5e5' : undefined,
                                borderRight: isLastOfGroup && !isLastColumn ? '2px solid #e5e5e5' : undefined,
                            }}
                        />
                    );
                })}
            </colgroup>
            <thead>
                <tr className={styles.tableHeadRow}>
                    <th className={styles.tableHeadCell} />
                    <th className={styles.tableHeadCell} />
                    {headerGroups.map((group, groupIndex) => {
                        const { title, span, start } = group;

                        return (
                            <th
                                key={'' + start as string}
                                colSpan={span}
                                className={styles.tableHeadGroupCell}
                                style={{ paddingLeft: groupIndex === 0 ? '.5em' : undefined }}
                            >
                                {title}
                            </th>
                        )
                    })}
                </tr>
                <tr
                    className={styles.tableHeadRow}
                    // @ts-ignore
                    style={{ '--sticky-top': '32px' }}
                >
                    <th className={styles.tableHeadCell} />
                    <th className={styles.tableHeadCell} />
                    {M5_FOOTER_COLUMNS.map((column, columnIndex) => {
                        const { title, prop } = column;
                        const isFirstOfGroup = headerGroups.some((group) => group.start === columnIndex);

                        return (
                            <th
                                key={prop as string}
                                className={styles.tableHeadCell}
                            >
                                <div
                                    className={styles.tableHeadCellContent}
                                    style={{
                                        paddingLeft: isFirstOfGroup ? '.5em' : undefined
                                    }}
                                >
                                    {title}
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => {
                    const cells = M5_FOOTER_COLUMNS.map((column, columnIndex) => {
                        const { prop, editable, foilSide: columnFoilSide } = column;
                        const isFirstOfGroup = headerGroups.some((group) => group.start === columnIndex);

                        if (editable) {
                            const value = localInputValue?.rowIndex === rowIndex && localInputValue.columnProp === prop
                                ? localInputValue.value
                                : row[prop];

                            return (
                                <td
                                    key={prop as string}
                                >
                                    <TableInput
                                        value={value}
                                        onChange={(newValue) => handleInputChange(rowIndex, prop, newValue)}
                                    />
                                </td>
                            )
                        }

                        if (!columnFoilSide) {
                            return (
                                <td
                                    key={prop as string}
                                    className={styles.tableCell}
                                    style={{ paddingLeft: isFirstOfGroup ? '.5em' : undefined }}
                                >
                                    {row[prop]}
                                </td>
                            )
                        }

                        const isSelected = columnFoilSide === selectedFoilSide && rowIndex === selectedRowIndex;

                        return (
                            <td
                                key={prop as string}
                                className={classNames(styles.tableCell, isSelected && styles.tableCellSelected)}
                                style={{ paddingLeft: isFirstOfGroup ? '.5em' : undefined }}
                                // @ts-ignore Don't select text on double click
                                onMouseDown={(e) => e.detail === 2 && e.preventDefault()}
                                onDoubleClick={() => {
                                    if (!isSelected) {
                                        setSelectedFoilSide(columnFoilSide);
                                        setSelectedRowIndex(rowIndex);
                                    }
                                }}
                            >
                                {row[prop]}
                            </td>
                        )
                    })

                    const key = (row.leftFoilNumber && row.rightFoilNumber)
                        ? row.leftFoilNumber + '///' + row.rightFoilNumber
                        : rowIndex;

                    return (
                        <tr
                            key={key}
                            className={styles.tableRow}
                        >
                            <td className={styles.tableCheckboxCell}>
                                <input
                                    type="checkbox"
                                    checked={true}
                                    onChange={() => handleRemoveRow(rowIndex)}
                                />
                            </td>
                            <td className={styles.tableOrderCell}>
                                {rowIndex + 1}.
                            </td>
                            {cells}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default M5Footer;

type HeaderGroup = {
    foilSide: 'left' | 'right' | undefined,
    title: string,
    span: number,
    start: number,
    stop: number,
}

const getHeaderGroupTitle = (foilSide: 'left' | 'right' | undefined): string => {
    switch (foilSide) {
        case 'left': return 'Left foil';
        case 'right': return 'Right foil';
        default: return '';
    }
}

const getHeaderGroups = (columns: M5FooterColumn[]): HeaderGroup[] => {
    const groups: HeaderGroup[] = [];

    for (const column of columns) {
        const { foilSide } = column;
        const lastGroup = groups[groups.length - 1];

        if (!groups.length) {
            groups.push({
                foilSide,
                title: getHeaderGroupTitle(foilSide),
                span: 1,
                start: 0,
                stop: 0,
            });
        }
        else if (lastGroup.foilSide === foilSide) {
            lastGroup.span++;
            lastGroup.stop++;
        }
        else {
            groups.push({
                foilSide,
                title: getHeaderGroupTitle(foilSide),
                span: 1,
                start: lastGroup.stop + 1,
                stop: lastGroup.stop + 1,
            })
        }
    }

    return groups;
}
