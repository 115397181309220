import React from 'react';
import { RetrieveProductRecordingInstruction } from '../azumuta';
import styles from './InstructionOverview.module.css';
import TableAnswer from './TableAnswer';

type InstructionOverviewProps = {
    instruction: RetrieveProductRecordingInstruction,
    currentFoilNumber: string,
}

const InstructionOverview = (props: InstructionOverviewProps) => {
    const { instruction, currentFoilNumber } = props;

    const { answer } = instruction;
    const hasAnswer = !!answer;
    const hasTableAnswer = hasAnswer && answer.type === 'table';

    return (
        <div className={styles.instruction}>
            <div className={styles.instructionGutter}>—</div>
            <div className={styles.instructionContent}>
                <header className={styles.instructionHeader}>
                    <span>{instruction.name}</span>
                    {hasAnswer &&
                        <aside className={styles.instructionDetails}>
                            <span>by</span>
                            <span><a href={'mailto:' + answer.userEmail}>{answer.user}</a></span>
                            <span>on</span>
                            <span>{answer.timestamp}</span>
                        </aside>
                    }
                </header>
                {hasAnswer &&
                    <main className={styles.instructionAnswer}>
                        {hasTableAnswer &&
                            <TableAnswer
                                table={answer.value}
                                currentFoilNumber={currentFoilNumber}
                            />
                        }
                        {answer.type === 'string' &&
                            <div className={styles.stringAnswer}>{answer.value}</div>
                        }
                    </main>
                }
            </div>
        </div>
    );
}

export default InstructionOverview;
