import React, { useState } from 'react';
import { RowFilter } from '../modals/common';
import styles from './Filter.module.css';
import { useDebouncedCallback } from '../util/useDebouncedCallback';

type FilterProps = {
    query: string,
    setQuery: (query: string) => void,
    filters: RowFilter<any>[],
    activeFilters: number[],
    setActiveFilters: (activeFilters: number[]) => void,
}

const Filter = (props: FilterProps) => {
    const { query, setQuery, filters, activeFilters, setActiveFilters } = props;

    const [queryInputValue, setQueryInputValue] = useState(query);
    const updateQueryDebounced = useDebouncedCallback(setQuery, 300);

    const toggleFilter = (filterIndex: number) => {
        if (activeFilters.includes(filterIndex)) {
            setActiveFilters(activeFilters.filter((activeFilterIndex) => activeFilterIndex !== filterIndex));
        }
        else {
            setActiveFilters([...activeFilters, filterIndex]);
        }
    }

    return (
        <div className={styles.wrapper}>
            {/* <span className={styles.wrapperLabel}>Filter:</span> */}
            <input
                type={'text'}
                placeholder={'Filter'}
                className={styles.queryInput}
                value={queryInputValue}
                onChange={(e) => {
                    setQueryInputValue(e.target.value);
                    updateQueryDebounced(e.target.value);
                }}
            />
            {filters.map((filter: RowFilter<any>, filterIndex: number) => {
                const { label } = filter;

                return (
                    <label
                        key={filterIndex}
                        className={styles.filter}
                    >
                        <input type={'checkbox'} onChange={() => toggleFilter(filterIndex)} />
                        <span className={styles.filterLabel}>{label}</span>
                    </label>
                )
            })}
        </div>
    );
};

export default Filter;

